import axios from "../axios";

function getEvents() {
  return axios.get("/events");
}

function getEventById(id) {
  return axios.get("/events/" + id);
}

function getEventsByDate(limit, page) {
  return axios.get(
    "/events?sort_by=startDate.desc" + "&limit=" + limit + "&page=" + page
  );
}
export { getEvents, getEventById, getEventsByDate };
