<template>
  <div class="wrapper">
    <div class="page-header header-filter page-header-small">
      <div class="page-header-image" :style="
        'background-image: url( https://fortunetown.co.th/wp-content/themes/plant/images/cover_homepage.png);'
      "></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-md-7 mr-auto text-left">
            <h1 class="title text-white">{{ storeName }}</h1>
            <br />
          </div>
        </div>
      </div>
    </div>
    <div class="section section-item pt-2 product-section">
      <div class="container" v-if="loading">
        <div class="d-flex justify-content-center mb-3">
          <b-spinner style="width: 3rem; height: 3rem;" label="Loading..."></b-spinner>
        </div>
        <p class=" text-center">กรุณารอสักครู่...</p>
      </div>
      <div class="container my-6" v-else>
        <div class="row">
          <div class="col-lg-6 col-md-12 ">
            <b-carousel id="carousel1" ref="carousel1">
              <!-- Text slides with image -->
              <b-carousel-slide :img-src="productInfo.product_image_url">
              </b-carousel-slide>
              <a class="carousel-control-prev" v-on:click="prev" role="button" data-slide="prev">
                <button type="button" class="btn btn-warning btn-icon btn-round btn-sm" name="button">
                  <i class="ni ni-bold-left"></i>
                </button>
              </a>

              <a class="carousel-control-next" v-on:click="next" role="button" data-slide="next">
                <button type="button" class="btn btn-warning btn-icon btn-round btn-sm" name="button">
                  <i class="ni ni-bold-right"></i>
                </button>
              </a>
            </b-carousel>
          </div>
          <div class="col-lg-6 col-md-12 mx-auto mt-4-sp">
            <div>
              <router-link :to="'/shops/' + productInfo.shop.id">
                <img :src="productInfo.shop.profile_image_url" class=" b-avatar-large rounded-circle" />
              </router-link>
              <h2 class="title">{{ productInfo.name }}</h2>
              <p v-if="maxQuantity != 0 && !productInfo.discount" class="main-price h3 text-blue">
                {{ numberWithCommas(productInfo.price) }} บาท
              </p>
              <p v-else>
                <span class="main-price h3 font-weight-light text-gray text-decoration-through">{{
                    numberWithCommas(productInfo.price)
                }} บาท</span>
                <span v-if="productInfo.discount" class="main-price h3 text-blue">&nbsp;&nbsp;{{
                    numberWithCommas(
                      productInfo.price * ((100 - productInfo.discount.discount_percent) / 100)
                    )
                }}
                  บาท</span>
                <span v-else class=" text-danger mt-3">
                  &nbsp;&nbsp; สินค้าหมด</span>
              </p>
              <p v-if="maxQuantity <= 5 && maxQuantity != 0" class=" text-danger mt-3">
                สินค้าเหลือเพียง {{ maxQuantity }} ชิ้น
              </p>
              <p class="description">
                {{ productInfo.description }}
              </p>
            </div>
            <hr class=" w-100 my-4" />
            <div>
              <p>
                <strong>ร้าน: </strong>{{ productInfo.shop.name }}<br />
                <strong>ช่องทางติดต่อ: </strong><br />
                <span v-if="productInfo.shop.address">
                  <i class="fa fa-building-o"></i>&nbsp;&nbsp;
                  {{ productInfo.shop.address }}<br />
                </span>
                <span v-if="productInfo.shop.phoneNumber">
                  <i class="fa fa-phone"></i>&nbsp;&nbsp;
                  {{ productInfo.shop.phoneNumber }}<br />
                </span>
              </p>
              <router-link :to="'/shops/' + productInfo.shop.id + '/products'">
                <base-button type="primary" class=" mt-0">
                  ดูสินค้าอื่นๆในร้าน
                </base-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BCarousel } from "bootstrap-vue/esm/components/carousel/carousel";
import { BCarouselSlide } from "bootstrap-vue/esm/components/carousel/carousel-slide";
import { BSpinner } from "bootstrap-vue/esm/components/spinner/spinner";
import * as api from "@/services";
export default {
  bodyClass: "product-page",
  components: {
    BCarousel,
    BCarouselSlide,
    BSpinner,
  },
  data() {
    return {
      productInfo: null,
      maxQuantity: 99,
      storeName: "",
      loading: true,
    };
  },
  watch: {},
  computed: {},
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    prev() {
      this.$refs.carousel1.prev();
    },
    next() {
      this.$refs.carousel1.next();
    },
  },
  async created() {
    api
      .getProductById(this.$route.params.productid)
      .then((res) => {
        this.productInfo = res.data;

      })
      .then(() => {
        this.storeName = this.productInfo.shop.name;
        this.maxQuantity = this.productInfo.inventory.quantity;
        this.loading = false;
      });

  },
};
</script>
<style>
.page-header-small {
  min-height: 30vh !important;
}

.product-page .carousel-item {
  height: 60vh;
}

.product-page .carousel .carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-page .carousel a.carousel-control-next {
  right: -20px;
}

.product-page .carousel a.carousel-control-prev {
  left: -20px;
}

.b-avatar-large {
  width: 6rem;
}

.text-decoration-through {
  text-decoration: line-through;
}

@media only screen and (min-width: 768px) {
  .container-5-3 {
    margin: 5rem 0 3rem 0;
  }
}

@media only screen and (device-width: 768px) {
  .mt-4-sp {
    margin-top: 1.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .mt-4-sp {
    margin-top: 1.5rem;
  }
}
</style>
