const getDefaultState = () => {
  return {
    userId: "",
    displayName: "",
    pictureUrl: "",
    isLineClient: false,
    email: "",
    phone: "",
    //   omiseId: "",
    //  description: "",
    // metadata: {},
  };
};

const state = getDefaultState;

const getters = {};

const actions = {
  lineLogin({ commit }, object) {
    commit("setUserId", object.userId);
    commit("setDisplayName", object.displayName);
    commit("setPictureUrl", object.pictureUrl);
    commit("setIsLineClient", true);
  },
  resetState({ commit }) {
    commit("RESET_STATE");
  },
};

const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  setUserId(state, value) {
    state.userId = value;
  },
  setDisplayName(state, value) {
    state.displayName = value;
  },
  setPictureUrl(state, value) {
    state.pictureUrl = value;
  },

  setOmiseId(state, value) {
    state.omiseId = value;
  },
  setCustomerData(state, obj) {
    state.email = obj.email;
    state.phone = obj.phoneNumber;
    //    state.omiseId = obj.omiseId;
  },
  setIsLineClient(state, bool) {
    state.isLineClient = bool;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
