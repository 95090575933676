const getDefaultState = () => {
  return {
    cartStoreName: "",
    cartItems: [],
    cartStoreId: 0,
    cartStoreImage: "",
  };
};

const state = getDefaultState;

const getters = {
  cartUniqueItemsCount: (state) => state.cartItems.length,
  isCartEmpty: (state) => state.cartItems.length == 0,
  cartTotal: (state) => {
    return state.cartItems
      .reduce((acc, cartItem) => {
        return cartItem.quantity * cartItem.productPrice + acc;
      }, 0)
      .toFixed(2);
  },
  cartQuantity: (state) => {
    return state.cartItems.reduce((acc, cartItem) => {
      return cartItem.quantity + acc;
    }, 0);
  },
};

const actions = {
  addCartItem({ commit, state, getters }, cartItem) {
    if (getters.cartUniqueItemsCount == 0) {
      commit("setCartItems", [cartItem]);
    } else {
      if (state.cartItems.some((x) => x.productId == cartItem.productId)) {
        let temp = state.cartItems;
        temp.map((x) => {
          if (x.productId == cartItem.productId) {
            x.quantity = cartItem.quantity;
          }
        });
        commit("setCartItems", temp);
      } else {
        commit("updateCartItems", cartItem);
      }
    }
  },
  async removeCartItem({ commit, state, getteres }, cartItemId) {
    let temp = state.cartItems;
    for (var i = 0; i < temp.length; i++) {
      if (temp[i].productId === cartItemId) {
        temp.splice(i, 1);
      }
    }

    await commit("setCartItems", temp);
    if (getters.isCartEmpty) {
      commit("setStoreName", "");
    }
  },
  removeAllCartItems({ commit }) {
    commit("setStoreName", "");
    commit("setCartItems", []);
  },
  addToCart() {},
  resetState({ commit }) {
    commit("RESET_STATE");
  },
};

const mutations = {
  setStoreId(state, value) {
    state.cartStoreId= value;
  },
  setStoreImage(state, value) {
    state.cartStoreImage = value;
  },
  setStoreName(state, storeName) {
    state.cartStoreName = storeName;
  },
  setCartItems(state, payload) {
    state.cartItems = payload;
  },
  updateCartItems(state, payload) {
    state.cartItems.push(payload);
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
