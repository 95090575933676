<template>
  <div class="wrapper">
    <div class="section-shaped my-0 skew-separator skew-mini">
      <div class="page-header page-header-small header-filter">
        <div
          class="page-header-image"
          style="background-image: url('img/pages/photo-15.jpg');"
        ></div>
        <div class="container">
          <div class="header-body text-center mb-7">
            <div class="row justify-content-center">
              <div class="col-xl-5 col-lg-6 col-md-8 px-5">
                <h1 class="mt-5 text-white">สินค้าในตะกร้า</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="upper">
      <div>
        <div class="row justify-content-center ml-0">
          <div class="col-lg-6 pl-0">
            <div class="">
              <div class=" w-100">
                <div class="card">
                  <div class="card-body">
                    <h3 v-if="!isCartEmpty" class="title mt-3">
                      สินค้าจากร้าน {{ storeName }}
                    </h3>
                    <fade-transition>
                      <div
                        v-if="isCartEmpty"
                        class=" row flex-column justify-content-center align-content-center mt-5"
                      >
                        <img
                          src="/img/empty-cart.png"
                          width="120"
                          class="mb-3"
                        />
                        <p class=" font-weight-normal text-center">
                          ไม่มีสินค้าในตะกร้า
                        </p>
                        <base-button
                          tag="a"
                          nativeType="submit"
                          type="info"
                          class="text-white"
                          href="/shop"
                          >Shop Now</base-button
                        >
                      </div>
                    </fade-transition>

                    <div
                      class="media align-items-center mb-2"
                      v-for="(item, index) in cartItems"
                      :key="index"
                    >
                      <div class="col-md-4 col-4">
                        <img
                          :src="item.productImg"
                          alt="Rounded image"
                          width="100%"
                          style="object-fit:cover;min-height:80px;min-width:80px;"
                        />
                      </div>
                      <div class="media-body">
                        <h2 class="h6">{{ item.productName }}</h2>
                        <p class="my-0">จำนวน {{ item.quantity }} ชิ้น</p>
                        <p class="my-0">
                          {{ numberWithCommas(item.productPrice) }} บาท
                        </p>
                      </div>

                      <base-button
                        class="btn btn-icon-only rounded-circle bg-white border-0 shadow-none"
                        @click="removeItem(item.productId)"
                      >
                        <span class="btn-inner--icon"
                          ><svg
                            class="icon-red"
                            width="10"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <!--! Font Awesome Pro 6.1.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                            <path
                              d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                            /></svg
                        ></span>
                      </base-button>
                    </div>

                   
                    <hr class="line-info mb-3" />
                    <div class="media align-items-center">
                      <h3 class="h6 opacity-8 mr-3">รวมยอดสินค้า</h3>
                      <div class="media-body text-right">
                        <span>{{ numberWithCommas(cartTotal) }} บาท</span>
                      </div>
                    </div>

                    <base-button
                      tag="a"
                      class=" w-100 mt-3"
                      nativeType="submit"
                      type="info"
                      href="/checkout"
                      >สั่งซื้อเลย</base-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { FadeTransition } from "vue2-transitions";

export default {
  bodyClass: "checkout-page",
  components: {
    FadeTransition,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("cart", ["storeName", "cartItems"]),
    ...mapGetters("cart", ["cartUniqueItemsCount", "isCartEmpty", "cartTotal"]),
  },
  methods: {
    ...mapActions("cart", ["removeCartItem"]),
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    removeItem(productId) {
      this.removeCartItem(productId);
    },
  },
};
</script>
<style scoped>
.btn.btn-icon-only .btn-inner--icon i {
  top: -17px !important;
  left: -6px !important;
}
svg.icon-red {
  filter: invert(14%) sepia(100%) saturate(2477%) hue-rotate(346deg)
    brightness(115%) contrast(99%);
}
</style>
