<template>
  <div>
    <image-upload ref="image" @change="process"> </image-upload>
    <p>{{ img }}</p>
  </div>
</template>
<script>
import axios from "axios";
import ImageUpload from "@/components/ImageUpload.vue";
export default {
  components: { ImageUpload },
  data() {
    return {
      img: [],
    };
  },
  methods: {
    process(value) {
      this.img = value;
      console.log(value)
      const data = new FormData();
      data.append("file", value);
      var config = {
        method: "post",
        url: "https://api-ft-staging.devfinite.solutions/v1/files/upload/shop",
        data: data,
      };

      axios(config)
        .then(function(response) {
          console.log(JSON.stringify(response.data));
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>
