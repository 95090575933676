<template>
  <div class="wrapper">
    <div class="page-header page-header-small header-filter">
      <div
        class="page-header-image"
        style="background-image: url('https://fortunetown.co.th/wp-content/themes/plant/images/cover_homepage.png');"
      ></div>
      <div class="content-center">
        <div class="row">
          <div class="col-md-6 mx-auto text-center">
            <h1 class="title text-white">
              Promotions
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div class="main mt-4">
      <div class="container" v-if="loading">
        <div class="d-flex justify-content-center mb-3">
          <b-spinner
            style="width: 3rem; height: 3rem;"
            label="Loading..."
          ></b-spinner>
        </div>
        <p class=" text-center">กรุณารอสักครู่...</p>
      </div>
      <div v-else class="container mt-6">
        <br />
        <p v-if="displayedPromotions.length==0" class=" text-center" >ขออภัย ขณะนี้ยังไม่มีโปรโมชั่น...</p>
        <template v-else>
          <Blog
            v-for="(item, index) in displayedPromotions"
            :key="index"
            :name="item.name ? item.name : ''"
            :description="item.description"
            :img_url="
              item.promotion_image_url
                ? item.promotion_image_url
                : '/img/promotion_placeholder.jpg'
            "
            :subtitle="
              displayedShops[index] ? displayedShops[index].name : ''
            "
            :promotionId="item.id"
            :startDate="convertDate(item.startDate)"
            :endDate="convertDate(item.endDate)"
            :shopId="item.shopId"
          ></Blog>
        </template>
        <br />
        <br />
        <div class=" d-flex justify-content-center">
          <base-pagination
            :page-count="totalPage"
            v-model="currentPage"
          ></base-pagination>
        </div>
        <br />
        <br />
      </div>
    </div>
  </div>
</template>
<script>
import Blog from "./widgets/PromoThumbnail.vue";
import * as api from "@/services";
import { BSpinner } from "bootstrap-vue/esm/components/spinner/spinner";
export default {
  components: {
    Blog,
    BSpinner,
  },
  data() {
    return {
      promotions: [],
      shop_promotions: [],
      loading: true,
      pagination: 10,
      currentPage: 1,
    };
  },
  methods:{
     monthToText(monthNum) {
      let month = [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ];
      return month[monthNum - 1];
    },
    convertDate(date) {
      let year = date.substring(0, 4);
      let month = date.substring(5, 7);
      let day = date.substring(8, 10);
      return day + "/" + month + "/" + year;
    },
  },
  computed: {
    totalPage() {
      return Math.ceil(this.promotions.length / this.pagination);
    },
    totalPromotion() {
      return this.promotions.length;
    },
    displayedPromotions() {
      let startPoint = (this.currentPage - 1) * this.pagination;
      return this.promotions.slice(startPoint, 10);
    },
    displayedShops(){
      let startPoint = (this.currentPage - 1) * this.pagination;
      return this.shop_promotions.slice(startPoint, 10);
    }
  },
  async created() {
    await api
      .getPromotions()
      .then(async (res) => {
        this.promotions = await res.data.results;
      })
      .then(async () => {
        this.shop_promotions = [];
        for (let i = 0; i < this.promotions.length; i++) {
          api.getShopById(this.promotions[i].shopId).then((res) => {
            this.shop_promotions.push(res.data);
          });
        }
      });

    this.loading = await false;
  },
};
</script>
<style></style>
