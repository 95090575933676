<template>
  <section class="blogs-3">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 col-md-8 mx-auto">
          <div class="card card-blog card-plain blog-horizontal mb-5">
            <div class="row">
              <div class="col-lg-4">
                <div class="card-image ">
                  <a>
                    <img class="img rounded " :src="img_url" />
                  </a>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="card-body">
                  <h3 class="card-title">
                    <a :href="'shops/' + shopId + '/products?promotion=' + promotionId" v-if="name">{{
                      name
                    }}</a>
                    <p v-if="subtitle" class="mb-0">
                      By {{ subtitle }}
                      <br />
                      <span class=" text-gray text-xs">
                        {{ startDate }} -
                        {{ endDate }}
                      </span>
                    </p>
                  </h3>
                  <p class="card-description">
                    {{ description }}
                  </p>
                  <router-link :to="'shops/' + shopId + '/products?promotion=' + promotionId">
                    <base-button type="primary" class=" mt-0 ">
                      ดูสินค้าเข้าร่วมโปรโมชั่น
                    </base-button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "promo-thumbnail",
  data() {
    return {};
  },
  props: {
    name: String,
    subtitle: String,
    description: String,
    img_url: String,
    promotionId: Number,
    startDate: String,
    endDate: String,
    shopId:Number
  },
};
</script>
<style></style>
