import Vue from "vue";
import Router from "vue-router";
import { createRouter, createWebHistory } from "vue-router";

import Header from "./layout/AppHeader";
import Footer from "./layout/AppFooter";

import Checkout from "./views/Checkout/Checkout.vue";
import Cart from "./views/Checkout/Cart.vue";
import Ecommerce from "./views/Shopping/Ecommerce.vue";
import ProductPage from "./views/Shopping/ProductPage.vue";
import ShopPage from "./views/Shopping/Shop.vue";
import ShopPromoPage from "./views/Shopping/ShopPromo.vue";
import StorePage from "./views/Store/StorePage.vue";
import Promotions from "./views/Blog/Promotions.vue";
import Events from "./views/Blog/Events.vue";
import Error from "./views/Error.vue";
import Test from "./views/Checkout/Test.vue";
import OrderFailed from "./views/Checkout/OrderFailed.vue";
import Success from "./views/Checkout/Success.vue";
import Invoice from "./views/Checkout/Invoice.vue";
import Register from "./views/User/Register.vue";

//import Loading from "./views/Loading/Spinner.vue";

import ShowStores from "./views/Store/ShowStores.vue";
Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      components: {
        header: Header,
        default: Ecommerce,
        footer: Footer,
      },
    },
    {
      path: "/register",
      name: "Register",
      components: {
        default: Register,
      },
    },
    {
      path: "/invoice",
      name: "Invoice",
      components: {
        header: Header,
        default: Invoice,
      },
    },
    {
      path: "/test",
      name: "Test",
      components: {
        header: Header,
        default: Test,
      },
    },
    {
      path: "/sorry",
      name: "Sorry",
      components: {
        default: OrderFailed,
      },
    },
    {
      path: "/success",
      name: "Success",
      components: {
        default: Success,
      },
    },
    {
      path: "/categories/:categoryId",
      name: "Stores",
      components: {
        header: Header,
        default: ShowStores,
        footer: Footer,
      },
    },
    {
      path: "/shops/:shopid/products/:productid",
      components: {
        header: Header,
        default: ProductPage,
        footer: Footer,
      },
    },
    {
      path: "/shops/:shopid/products",
      components: {
        header: Header,
        default: ShopPage,
        footer: Footer,
      },
    },
    {
      path: "/shops/:shopid",
      components: {
        header: Header,
        default: StorePage,
        footer: Footer,
      },
    },
    {
      path: "/products",
      components: {
        header: Header,
        default: StorePage,
        footer: Footer,
      },
    },
    {
      path: "/cart",
      name: "cart",
      components: {
        header: Header,
        default: Cart,
        footer: Footer,
      },
    },
    {
      path: "/checkout",
      name: "checkout",
      components: {
        header: Header,
        default: Checkout,
        footer: Footer,
      },
    },
    {
      path: "/promotions",
      name: "Promotions",
      components: {
        header: Header,
        default: Promotions,
        footer: Footer,
      },
    },
    {
      path: "/promotion/:promotionId",
      name: "ProductPromotion",
      components: {
        header: Header,
        default: ShopPromoPage,
        footer: Footer,
      },
    },
    {
      path: "/events",
      name: "Events",
      components: {
        header: Header,
        default: Events,
        footer: Footer,
      },
    },
    {
      path: "*",
      name: "error",
      components: {
        default: Error,
      },
      props: {
        header: { navbarType: "default" },
      },
    },
    
  ],
});
