<template>
  <div class="wrapper">
    <div
      class="page-header page-header-small header-filter"
    >
      <div
        class="page-header-image"
        :style="'background-image: url( https://fortunetown.co.th/wp-content/themes/plant/images/cover_homepage.png);'"
      ></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-md-7 mr-auto text-left">
            <h1 v-if="!loading" class="title text-white">
              {{ shopInfo.name }}
            </h1>
            <br />
          </div>
        </div>
      </div>
     
    </div>
    <div class="section section-item">
      <div class="container" v-if="loading">
        <div class="d-flex justify-content-center mb-3 ">
          <b-spinner
            style="width: 3rem; height: 3rem;"
            label="Loading..."
          ></b-spinner>
        </div>
        <p class=" text-center">กรุณารอสักครู่...</p>
      </div>
      <div class="container my-6" v-if="!loading">
        <div class="row">
          <div class="col-lg-6 col-md-12 d-flex">
            <img
              :src="shopInfo.profile_image_url"
              width="250px"
              height="250px"
              class="rounded-circle mx-auto cover-full"
            />
          </div>
          <div class="col-lg-6 col-md-12 mx-auto mt-4-sp px-5">
            <div>
              <h2><strong></strong>{{ shopInfo.name }}</h2>
              <p v-if="shopInfo.address">
                <strong>ที่อยู่ร้าน: </strong><br />
                <i class="fa fa-building-o"></i>&nbsp;&nbsp;
                {{ shopInfo.address }}<br /><br />
              </p>
              <p
                v-if="
                  shopInfo.phoneNumber ||
                    shopInfo.lineId ||
                    shopInfo.fbName ||
                    shopInfo.website
                "
              >
                <strong>ติดต่อร้าน: </strong><br />
                <span v-if="shopInfo.phoneNumber">
                  <i class="fa fa-phone"></i>&nbsp;&nbsp;
                  {{ shopInfo.phoneNumber }}<br />
                </span>
                <span v-if="shopInfo.lineId">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    width="16"
                    style="filter: invert(11%) sepia(90%) saturate(836%) hue-rotate(190deg) brightness(94%) contrast(93%);"
                  >
                    <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M272.1 204.2v71.1c0 1.8-1.4 3.2-3.2 3.2h-11.4c-1.1 0-2.1-.6-2.6-1.3l-32.6-44v42.2c0 1.8-1.4 3.2-3.2 3.2h-11.4c-1.8 0-3.2-1.4-3.2-3.2v-71.1c0-1.8 1.4-3.2 3.2-3.2H219c1 0 2.1.5 2.6 1.4l32.6 44v-42.2c0-1.8 1.4-3.2 3.2-3.2h11.4c1.8-.1 3.3 1.4 3.3 3.1zm-82-3.2h-11.4c-1.8 0-3.2 1.4-3.2 3.2v71.1c0 1.8 1.4 3.2 3.2 3.2h11.4c1.8 0 3.2-1.4 3.2-3.2v-71.1c0-1.7-1.4-3.2-3.2-3.2zm-27.5 59.6h-31.1v-56.4c0-1.8-1.4-3.2-3.2-3.2h-11.4c-1.8 0-3.2 1.4-3.2 3.2v71.1c0 .9.3 1.6.9 2.2.6.5 1.3.9 2.2.9h45.7c1.8 0 3.2-1.4 3.2-3.2v-11.4c0-1.7-1.4-3.2-3.1-3.2zM332.1 201h-45.7c-1.7 0-3.2 1.4-3.2 3.2v71.1c0 1.7 1.4 3.2 3.2 3.2h45.7c1.8 0 3.2-1.4 3.2-3.2v-11.4c0-1.8-1.4-3.2-3.2-3.2H301v-12h31.1c1.8 0 3.2-1.4 3.2-3.2V234c0-1.8-1.4-3.2-3.2-3.2H301v-12h31.1c1.8 0 3.2-1.4 3.2-3.2v-11.4c-.1-1.7-1.5-3.2-3.2-3.2zM448 113.7V399c-.1 44.8-36.8 81.1-81.7 81H81c-44.8-.1-81.1-36.9-81-81.7V113c.1-44.8 36.9-81.1 81.7-81H367c44.8.1 81.1 36.8 81 81.7zm-61.6 122.6c0-73-73.2-132.4-163.1-132.4-89.9 0-163.1 59.4-163.1 132.4 0 65.4 58 120.2 136.4 130.6 19.1 4.1 16.9 11.1 12.6 36.8-.7 4.1-3.3 16.1 14.1 8.8 17.4-7.3 93.9-55.3 128.2-94.7 23.6-26 34.9-52.3 34.9-81.5z"
                    /></svg
                  >&nbsp;&nbsp; {{ shopInfo.lineId }}<br />
                </span>
                <span v-if="shopInfo.fbName">
                  <svg
                    style="filter: invert(11%) sepia(90%) saturate(836%) hue-rotate(190deg) brightness(94%) contrast(93%);"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    width="16"
                  >
                    <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
                    />
                  </svg>
                  &nbsp;&nbsp; {{ shopInfo.fbName }}<br />
                </span>
                <span v-if="shopInfo.website">
                  <i class="ni ni-world-2"></i>&nbsp;&nbsp; {{ shopInfo.website
                  }}<br />
                </span>
              </p>
              <router-link :to="'/shops/' + shopInfo.id + '/products'">
                <base-button type="primary" class=" mt-0 ">
                  ดูสินค้าในร้าน
                </base-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BSpinner } from "bootstrap-vue/esm/components/spinner/spinner";
import * as api from "@/services";
export default {
  bodyClass: "product-page",
  components: {
    BSpinner,
  },
  data() {
    return {
      shopInfo: null,
      loading: true,
    };
  },
  watch: {},
  computed: {},
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    prev() {
      this.$refs.carousel1.prev();
    },
    next() {
      this.$refs.carousel1.next();
    },
  },
  created() {
    api.getShopById(this.$route.params.shopid).then((res) => {
      this.shopInfo = res.data;
      this.loading = false;
    });
  },
};
</script>
<style>
.product-page .carousel-item {
  height: 60vh;
}
.product-page .carousel .carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.product-page .carousel a.carousel-control-next {
  right: -20px;
}

.product-page .carousel a.carousel-control-prev {
  left: -20px;
}
.b-avatar-large {
  width: 6rem;
}
.text-decoration-through {
  text-decoration: line-through;
}
.cover-full{
  object-fit: cover!important;
}
@media only screen and (device-width: 768px) {
  .mt-4-sp {
    margin-top: 1.5rem;
  }
}
@media only screen and (max-width: 600px) {
  .mt-4-sp {
    margin-top: 1.5rem;
  }
}
</style>
