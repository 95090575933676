<template>
  <footer class="footer" v-if="userId">
    <div class="container">
      <div class="row row-grid align-items-center mb-5">
        <div class="col-lg-6">
          <img
            class="my-4"
            src="https://fortunetown.co.th/wp-content/uploads/2021/08/cropped-Group-133.png"
          />
          <h5 class="mb-0 font-weight-light text-white h6">
            7 Ratchadapisek road, Din Daeng, Bangkok 10400
          </h5>
        </div>
        <div class="col-lg-6 text-lg-center btn-wrapper">
          <base-button
            tag="a"
            target="_blank"
            href="https://www.facebook.com/FortuneTown/"
            rel="nofollow"
            class="btn-icon-only rounded-circle btn btn-facebook"
            data-toggle="tooltip"
            data-original-title="Like us"
          >
            <span class="btn-inner--icon"><i class="fa fa-facebook"></i></span>
          </base-button>
          <base-button
            tag="a"
            target="_blank"
            href="https://page.line.me/zmq5693v?openQrModal=true"
            rel="nofollow"
            class="btn btn-icon-only btn-twitter rounded-circle"
            data-toggle="tooltip"
            data-original-title="Follow us"
          >
            <span class="btn-inner--icon"
              ><svg
                class=" svg-white"
                width="12"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <!--! Font Awesome Pro 6.1.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M272.1 204.2v71.1c0 1.8-1.4 3.2-3.2 3.2h-11.4c-1.1 0-2.1-.6-2.6-1.3l-32.6-44v42.2c0 1.8-1.4 3.2-3.2 3.2h-11.4c-1.8 0-3.2-1.4-3.2-3.2v-71.1c0-1.8 1.4-3.2 3.2-3.2H219c1 0 2.1.5 2.6 1.4l32.6 44v-42.2c0-1.8 1.4-3.2 3.2-3.2h11.4c1.8-.1 3.3 1.4 3.3 3.1zm-82-3.2h-11.4c-1.8 0-3.2 1.4-3.2 3.2v71.1c0 1.8 1.4 3.2 3.2 3.2h11.4c1.8 0 3.2-1.4 3.2-3.2v-71.1c0-1.7-1.4-3.2-3.2-3.2zm-27.5 59.6h-31.1v-56.4c0-1.8-1.4-3.2-3.2-3.2h-11.4c-1.8 0-3.2 1.4-3.2 3.2v71.1c0 .9.3 1.6.9 2.2.6.5 1.3.9 2.2.9h45.7c1.8 0 3.2-1.4 3.2-3.2v-11.4c0-1.7-1.4-3.2-3.1-3.2zM332.1 201h-45.7c-1.7 0-3.2 1.4-3.2 3.2v71.1c0 1.7 1.4 3.2 3.2 3.2h45.7c1.8 0 3.2-1.4 3.2-3.2v-11.4c0-1.8-1.4-3.2-3.2-3.2H301v-12h31.1c1.8 0 3.2-1.4 3.2-3.2V234c0-1.8-1.4-3.2-3.2-3.2H301v-12h31.1c1.8 0 3.2-1.4 3.2-3.2v-11.4c-.1-1.7-1.5-3.2-3.2-3.2zM448 113.7V399c-.1 44.8-36.8 81.1-81.7 81H81c-44.8-.1-81.1-36.9-81-81.7V113c.1-44.8 36.9-81.1 81.7-81H367c44.8.1 81.1 36.8 81 81.7zm-61.6 122.6c0-73-73.2-132.4-163.1-132.4-89.9 0-163.1 59.4-163.1 132.4 0 65.4 58 120.2 136.4 130.6 19.1 4.1 16.9 11.1 12.6 36.8-.7 4.1-3.3 16.1 14.1 8.8 17.4-7.3 93.9-55.3 128.2-94.7 23.6-26 34.9-52.3 34.9-81.5z"
                /></svg></span>
          </base-button>
          <base-button
            tag="a"
            target="_blank"
            href="https://www.instagram.com/fortunetown/"
            rel="nofollow"
            class="btn btn-icon-only btn-dribbble rounded-circle"
            data-toggle="tooltip"
            data-original-title="Follow us"
          >
            <span class="btn-inner--icon"><i class="fa fa-instagram"></i></span>
          </base-button>
          <base-button
            tag="a"
            target="_blank"
            href="https://www.youtube.com/channel/UC0Ip4pgU1jjw5qiWCaR3SYw"
            rel="nofollow"
            class="btn btn-icon-only btn-youtube rounded-circle"
            data-toggle="tooltip"
            data-original-title="Follow us"
          >
            <span class="btn-inner--icon"><i class="fa fa-youtube"></i></span>
          </base-button>
        </div>
      </div>
      <div class="row align-items-center justify-content-md-between">
        <div class="col-md-6">
          <div class="copyright text-white">
            &copy; {{ year }}
            <a href="javascript:void();" target="_blank">Fortune Town</a>
          </div>
        </div>
        <div class="col-md-6">
          <ul class="nav nav-footer justify-content-end">
            <li class="nav-item">
              <a href="" class="nav-link" target="_blank">License</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "app-footer",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapState("customer", ["userId"]),
  },
};
</script>
<style>
.svg-white {
  filter: invert(100%) sepia(26%) saturate(19%) hue-rotate(359deg)
    brightness(106%) contrast(104%);
  top: -18px;
  left: -6px;
  position: relative;
}
.btn.btn-icon-only .btn-inner--icon i {
  top: -17px !important;
  left: -6px !important;
}
.btn.btn-facebook.btn-icon-only .btn-inner--icon i {
  left: -4px !important;
}
</style>
