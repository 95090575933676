<template>
  <div class="wrapper">
    <div class="page-header page-header-small header-filter">
      <div
        class="page-header-image"
        style="background-image: url('https://fortunetown.co.th/wp-content/themes/plant/images/cover_homepage.png');"
      ></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-md-7 mr-auto text-left">
            <h1 class="title text-white">หมวดหมู่ {{ category.name }}</h1>
            <br />
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="section z-index-10000">
        <div class="container">
          <div class="row">
            <div class="col-md-10 mx-auto text-center">
              <h3 class="desc my-5 ">รายชื่อร้านค้า</h3>
            </div>
          </div>
          <div class="row">
            <div
              class="col-lg-3 col-md-6"
              v-for="(item, index) in shops"
              :key="index"
            >
              <card class="card-product card-plain">
                <template slot="image">
                  <router-link
                    :to="{
                      path: '/shops/' + item.id,
                    }"
                  >
                    <img
                      :src="
                        item.profile_image_url
                          ? item.profile_image_url
                          : '/img/placeholder.jpg'
                      "
                      alt="..."
                      class=" sqr-img"
                    />
                  </router-link>
                </template>
                <template slot="body">
                  <h6 class="card-category text-danger text-center">
                    {{ category.name }}
                  </h6>
                  <router-link
                    :to="{
                      path: '/shops/' + item.id,
                    }"
                  >
                    <h5 class="card-title text-center">{{ item.name }}</h5>
                  </router-link>
                </template>
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VBTooltip } from "bootstrap-vue/esm/directives/tooltip/tooltip";
import { mapState } from "vuex";

export default {
  bodyClass: "ecommerce-page",

  data() {
    return {
      shops: [],
      category: {},
      categoryId: "",
    };
  },
  directives: {
    BTooltip: VBTooltip,
  },
  computed: {
    ...mapState("global", ["SHOPS", "SHOP_CATEGORIES"]),
  },
  methods: {
    setItem() {
      this.category = this.SHOP_CATEGORIES.find((x) => x.id == this.categoryId);
      this.shops = this.SHOPS.filter((x) => x.categoryId == this.category.id);
    },
  },
  watch: {
    "$route.params.categoryId": {
      handler: function() {
        this.categoryId = this.$route.params.categoryId;
        this.setItem();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.setItem();
  },
};
</script>

<style>
.ecommerce-page a.carousel-control-prev,
.ecommerce-page a.carousel-control-next {
  z-index: 9999;
}
.z-index-10000 {
  z-index: 10;
}
.ecommerce-page .page-header .buttons .btn i {
  top: 12px !important;
}
.skew-separator:after {
  z-index: 0 !important;
}
</style>
<style scoped>
.sqr-img {
  width: 200px !important;
  height: 200px !important;
  object-fit: cover;
  border-radius: 50%;
}
</style>
