<template>
  <div>
    <div v-if="!userId && isLineClient">
      <loading-animation />
    </div>
    <div v-else class="wrapper">
      <div class="container" v-if="loading">
        <div class="d-flex justify-content-center mb-3">
          <b-spinner style="width: 3rem; height: 3rem;" label="Loading..."></b-spinner>
        </div>
        <p class=" text-center">กรุณารอสักครู่...</p>
      </div>
      <div v-else>
        <div class="event-carousel " v-if="events">
          <b-carousel id="carousel1" ref="myCarousel">
            <b-carousel-slide v-for="(item, index) in events" :key="index" :img-src="item.event_img_url"
              class="home-carousel" />
            <a class="carousel-control-prev" v-on:click="prev" role="button" data-slide="prev">
              <button type="button" class="btn btn-icon  btn-sm" name="button">
                <i class="ni ni-bold-left text-white shadow-none"></i>
              </button>
            </a>
            <a class="carousel-control-next" v-on:click="next" role="button" data-slide="next">
              <button type="button" class="btn btn-icon btn-sm shadow-none" name="button">
                <i class="ni ni-bold-right text-white"></i>
              </button>
            </a>
          </b-carousel>
        </div>
        <div class="container" v-if="loading">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner style="width: 3rem; height: 3rem;" label="Loading..."></b-spinner>
          </div>
          <p class=" text-center">กรุณารอสักครู่...</p>
        </div>
        <div v-else class="my-6">
          <div class="container">
            <div class="row">
              <div class="col-md-10 mx-auto text-center">
                <h2 class="desc my-1 display-3">สินค้าแนะนำ</h2>
                <product-slider :products="products1" />
              </div>
            </div>
          </div>

          <div v-if="promotions.length>0" class="container text-center">
            <section class="blogs-1">
              <div class="container">
                <div class="row mb-5">
                  <div class="col-md-8 mx-auto">
                    <h2 class="display-3">โปรโมชั่น</h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6" v-for="(item, index) in promotions" :key="index">
                    <router-link :to="
                      'shops/' +
                      item.shopId +
                      '/products?promotion=' +
                      item.id
                    ">
                      <card class="card-blog card-background" data-animation="zooming">
                        <div v-if="item.promotion_image_url" class="full-background" :style="
                          'background-image: url(' +
                          item.promotion_image_url +
                          ')'
                        "></div>
                        <div v-else class="full-background" :style="
                          'background-image: url(/img/promotion_placeholder.jpg)'
                        "></div>
                        <template slot="body">
                          <div class="content-bottom">
                            <h3 class="card-title">
                              {{ item.name }}
                            </h3>
                            <h5 v-if="shop_promotions.length > 0 && item.shopId" class="text-white font-weight-light">
                              {{ getShopName(item.shopId) }}
                            </h5>
                          </div>
                        </template>
                      </card>
                    </router-link>
                  </div>
                </div>
                <router-link to="/promotions">
                  <base-button class="mt-4 mb-8" type="primary" icon="ni ni-bold-right">
                    Show more
                  </base-button>
                </router-link>
              </div>
            </section>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-md-10 mx-auto text-center">
                <h2 class="desc my-1 display-3">สินค้ามาใหม่</h2>
                <product-slider :products="products2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as api from "@/services";
import { VBTooltip } from "bootstrap-vue/esm/directives/tooltip/tooltip";
import { BCarousel } from "bootstrap-vue/esm/components/carousel/carousel";
import { BCarouselSlide } from "bootstrap-vue/esm/components/carousel/carousel-slide";
import "bootstrap-vue/dist/bootstrap-vue.min.css";
import ProductSlider from "./components/ProductSlider.vue";
import { mapState } from "vuex";
import LoadingAnimation from "./components/LoadingAnimation.vue";
import { BSpinner } from "bootstrap-vue/esm/components/spinner/spinner";
export default {
  bodyClass: "ecommerce-page",
  components: {
    BCarousel,
    BCarouselSlide,
    ProductSlider,
    BSpinner,
    LoadingAnimation,
  },
  data() {
    return {
      cartAlert: false,
      products1: {},
      products2: {},
      events: [],
      promotions: [],
      shop_promotions: {},
      loading: true,
    };
  },
  directives: {
    BTooltip: VBTooltip,
  },
  computed: {
    ...mapState("customer", ["userId", "isLineClient"]),
  },
  methods: {
    prev() {
      this.$refs.myCarousel.prev();
    },
    next() {
      this.$refs.myCarousel.next();
    },
    getShopName(shopId) {
      return this.shop_promotions.find((x) => x.id == shopId).name;
    },
  },
  created() {
    api.getEventsByDate(10, 1).then((res) => {
      this.events = res.data.results.filter( (o) => o.event_img_url!='')
    });
    api
      .getPromotionsByDate(4, 1)
      .then((res) => {
        this.promotions = res.data.results;
      })
      .then(() => {
        this.shop_promotions = [];
        for (let i = 0; i < this.promotions.length; i++) {
          api.getShopById(this.promotions[i].shopId).then((res) => {
            this.shop_promotions.push(res.data);
          });
        }
      });

    api.getProducts().then((res) => {
      function shuffle(array) {
        let currentIndex = array.length, randomIndex;
        while (currentIndex != 0) {
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
        return array;
      }
      this.products1 = shuffle(res.data.results).slice(0, 8);
    });
    api.getProductsSortById().then((res) => {
      this.products2 = res.data.results.slice(0, 8);
    });

    this.loading = false;
  },
};
</script>

<style>
.ecommerce-page a.carousel-control-prev,
.ecommerce-page a.carousel-control-next {
  z-index: 9999;
}

.z-index-10000 {
  z-index: 10000;
}

.ecommerce-page .page-header .buttons .btn i {
  top: 12px !important;
}

.card .card-title {
  margin-bottom: 0rem !important;
}

.carousel-item img {
  max-height: 40vh;
  object-fit: cover;
}

@media (max-width: 575.98px) {

  .slick-list,
  .slick-slider {
    margin-top: 8px !important;
  }
}
</style>
<style scoped>
.sqr-img {
  width: 200px !important;
  height: 200px !important;
  object-fit: cover;
  border-radius: 50%;
}

.card-description {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
