const getDefaultState = () => {
  return {
    SHOP_CATEGORIES: [],
    SHOPS: [],
  };
};

const state = getDefaultState;

const getters = {

};

const actions = {};

const mutations = {
  SET_SHOP_CATEGORIES(state, data) {
    state.SHOP_CATEGORIES = data;
  },
  SET_SHOPS(state, data) {
    state.SHOPS = data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
