<template>
  <div :class="col == '3' ? 'col-lg-3 col-md-6' : ' '">
    <card class="card-product card-plain">
      <template slot="image">
        <a href="javascript:;" @click="viewProduct()">
          <div class=" position-relative ">
            <div v-if="quantity == 0" class="sold-out-text text-white">
              Sold Out
            </div>
            <div v-if="quantity == 0" class="soldout-overlay "></div>
            <img :src="itemImg" alt="product-img" class="product-img" />
          </div>
        </a>
      </template>
      <template slot="body">
        <router-link :to="{ path: '/shops/' + shopId }">
          <h5 class=" text-black-50 small mb-0">
            {{ shopName }}
          </h5>
        </router-link>
        <a href="javascript:;" @click="viewProduct()">
          <h5 class="card-title ">{{ itemName }}</h5>
        </a>
        <div class="card-footer mt-0">
          <div class="price-container">
            <span v-if="!discount" class="price opacity-8">{{ numberWithCommas(itemPrice) }} บาท</span>

            <p v-else >
              <span class="main-price font-weight-light text-gray text-decoration-through">{{
                  numberWithCommas(itemPrice)
              }} บาท</span>
              <span class="main-price text-blue">&nbsp;&nbsp;{{
                  numberWithCommas(
                    itemPrice * ((100 - discount) / 100)
                  )
              }}
                บาท</span>
            </p>
          </div>
          <base-button type="danger" icon-only rounded size="sm" class="pull-right" icon="fa fa-eye"
            v-b-tooltip.hover.top title="ดูสินค้า" @click="viewProduct()"></base-button>
        </div>
      </template>
    </card>

  </div>
</template>
<script>
import { VBTooltip } from "bootstrap-vue/esm/directives/tooltip/tooltip";
export default {
  name: "product-card",
  directives: {
    BTooltip: VBTooltip,
  },
  data() {
    return {
      cartAlert: false,
    };
  },
  props: {
    col: {
      default: "3",
    },
    shopName: {
      type: String,
    },
    shopId: {
      type: Number,
    },
    itemName: {
      type: String,
    },
    itemId: {
      type: Number,
    },
    itemImg: {
      type: String,
      default: "/img/image_placeholer.jpg",
    },
    itemPrice: {
      type: Number,
    },
    quantity: {
      type: Number,
      default: 99,
    },
    discount: {
      type: Number,
      default: null,
    }
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    viewProduct() {
      this.$router.push({
        path: `/shops/${this.shopId}/products/${this.itemId}`,
      });
    },
  },
};
</script>
<style scoped>
.sold-out-text {
  font-size: 2em;
  width: 80%;
  height: 80%;
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.soldout-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.2);
  /*dim the background*/
}

.product-img {
  height: 220px;
  object-fit: contain;
}
</style>
