<template>
  <div class="wrapper">
    <div class="section-shaped my-0">
      <div class="page-header page-header-small header-filter">
        <div class="page-header-image"
          style="background-image: url('https://fortunetown.co.th/wp-content/themes/plant/images/cover_homepage.png');">
        </div>
        <div class="container">
          <div class="header-body text-center mb-7">
            <div class="row justify-content-center">
              <div class="col-xl-5 col-lg-6 col-md-8 px-5">
                <h1 class="text-white">ยินดีต้อนรับ</h1>
                <p class="text-lead text-white">
                  กรุณาลงทะเบียนก่อนเริ่มต้น
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="upper">
      <div class="container">
        <div class="col-lg-5 col-md-8 mx-auto">
          <div class="card bg-secondary shadow border-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4 pt-4">
                <img :src="pictureUrl" class=" b-avatar-large rounded-circle profile-img" />
                <p>
                  สวัสดีค่ะ คุณ <span>{{ displayName }}</span>
                </p>
              </div>
              <form role="form">
                <div class="form-group mb-3">
                  <base-input alternative placeholder="Email" addonLeftIcon="ni ni-email-83" v-model="email"
                    @click="error = ''"></base-input>
                  <base-input alternative placeholder="Phone" addonLeftIcon="fa fa-phone" v-model="phoneNumber"
                    @click="error = ''"></base-input>
                </div>
                <div class="custom-control-alternative custom-checkbox pl-0">
                  <base-checkbox alternative v-model="agree" :value="true" @change="error = ''">
                    <span>ข้าพเจ้ายินยอมให้ บริษัท ซี.พี.แลนด์ จำกัด (มหาชน) เก็บรวบรวม ใช้
                      และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าเพื่อการปรับปรุงและพัฒนาผลิตภัณฑ์และบริการ
                      วิเคราะห์และสำรวจความพึงพอใจของข้าพเจ้า เพื่อติดต่อ แจ้งเตือน หรือแจ้งข้อมูลข่าวสาร สิทธิประโยชน์
                      หรือวัตถุประสงค์สำหรับการตลาดทางตรงอื่นๆ อ่าน </span><span @click.stop="openModal('TermsModal')"
                      style=" cursor:pointer;" class=" text-primary">
                      เงื่อนไขและข้อตกลง</span><span> และ</span><span @click.stop="openModal('PrivacyModal')"
                      style=" cursor:pointer;" class=" text-primary">
                      นโยบายความเป็นส่วนตัว</span>
                  </base-checkbox>
                  <p v-if="error" class=" text-warning mt-2 mb-0 text-center text-sm-center">{{ error }}</p>
                </div>
                <div class="text-center">
                  <base-button type="primary" class="my-4" @click="handleSubmit()">ลงทะเบียน</base-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <terms-modal ref="TermsModal"></terms-modal>
    <privacy-modal ref="PrivacyModal"></privacy-modal>

  </div>
</template>
<script>
import TermsModal from "./components/TermsModal.vue";
import PrivacyModal from "./components/PrivacyModal.vue";
import { mapMutations, mapState } from "vuex";
import * as api from "@/services";
export default {
  name: "Register",
  components: {
    TermsModal,
    PrivacyModal,
  },
  bodyClass: "my-login-page",
  data() {
    return {
      modal: false,
      email: "",
      phoneNumber: "",
      agree: false,
      error: "",
    };
  },
  computed: {
    ...mapState("customer", ["userId", "displayName", "pictureUrl"]),
  },
  methods: {
    ...mapMutations("customer", ['setCustomerData']),
    openModal(ref) {
      this.$refs[ref].openModal()
    },
    handleSubmit() {
      if (this.agree) {
        if (this.email !== "" && this.phoneNumber !== "") {
          this.error = "";

          api
            .postCustomer({
              id: this.userId,
              email: this.email,
              phoneNumber: this.phoneNumber,
            })
            .then((res) => {
              this.setCustomerData(res.data);
              this.$router.push({ path: "/" });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.error = "กรุณากรอกข้อมูลให้ครบทุกช่อง";
        }
      } else {
        this.error = "กรุณายอมรับเงื่อนไขและข้อตกลง";
      }
    },
  },
};
</script>
<style scoped>
.profile-img {
  margin-top: -55%;
}

@media only screen and (max-width: 600px) {
  .profile-img {
    margin-top: -46%;
  }
}

@media only screen and (max-width: 960px) {
  .profile-img {
    margin-top: -25%;
  }
}

.my-login-page .container {
  margin-top: -40px;
}
</style>
