<template>
  <div class="wrapper">
    <div class="section-shaped my-0 skew-separator skew-mini">
      <div class="page-header page-header-small header-filter">
        <div
          class="page-header-image"
          style="background-image: url('img/pages/photo-15.jpg');"
        ></div>
        <div class="container">
          <div class="header-body text-center mb-7">
            <div class="row justify-content-center">
              <div class="col-xl-5 col-lg-6 col-md-8 px-5">
                <h1 class="text-white">เช็คเอ้าท์</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="upper">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="container">
              <h3 class="title text-white mt-3">สรุปรายการสินค้า</h3>
              <div class="row">
                <div class="card">
                  <div class="card-body mt-2">
                    <div
                      class="media align-items-center mb-2"
                      v-for="(item, index) in cartItems"
                      :key="index"
                    >
                      <div class="col-md-4 col-4">
                        <img
                          :src="item.productImg"
                          alt="Rounded image"
                          width="100%"
                          style="object-fit:cover;min-height:80px;min-width:80px;"
                        />
                      </div>
                      <div class="media-body">
                        <h2 class="h6">{{ item.productName }}</h2>
                        <p class="my-0">จำนวน {{ item.quantity }} ชิ้น</p>
                        <p class="my-0">
                          {{ numberWithCommas(item.productPrice) }} บาท
                        </p>
                      </div>
                    </div>
                    <hr class="line-info mb-3" />
                    <div class="media align-items-center">
                      <h3 class="h6 opacity-8 mr-3">รวมยอดสินค้า</h3>
                      <div class="media-body text-right">
                        <span>{{ numberWithCommas(cartTotal) }} บาท</span>
                      </div>
                    </div>
                    <div class="media align-items-center">
                      <h3 class="h6 opacity-8 mr-3">ค่าส่ง</h3>
                      <div class="media-body text-right">
                        <span>{{ numberWithCommas(100) }} บาท</span>
                      </div>
                    </div>
                    <hr class="line-info mb-3 mt-1" />
                    <div class="media align-items-center">
                      <h3 class="h6 opacity-8 mr-3">รวมยอดสินค้า</h3>
                      <div class="media-body text-right">
                        <span>{{ numberWithCommas(cartTotal) }} บาท</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <card bodyClasses="p-0">
              <template slot="body">
                <div class="container">
                  <h3 class="title mt-3">ข้อมูลสำหรับใช้รับสินค้า</h3>
                  <div class="row">
                    <div class="col-md-12">
                      <base-input
                        type="text"
                        v-model="customer.name"
                        placeholder=" "
                        required
                        label="ชื่อจริง นามสกุล"
                        @keypress="errorMsg = ''"
                      ></base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <base-input
                        v-model="customer.email"
                        type="email"
                        required
                        label="อีเมล"
                        @keypress="errorMsg = ''"
                      ></base-input>
                    </div>
                    <div class="col-md-6">
                      <base-input
                        v-model="customer.phone"
                        required
                        label="เบอร์โทร"
                        @keypress="isNumber"
                      ></base-input>
                    </div>
                  </div>
                  <div v-if="errorMsg" class="error-input py-1 ">
                    <p class="py-0 my-0 w-100 text-center text-sm">
                      {{ errorMsg }}
                    </p>
                  </div>
                  <br />
                  <!-- shipping Address
                  <div class="row">
                    <div class="col-md-8">
                      <base-input
                        type="text"
                        name="address"
                        required
                        label="ที่อยู่"
                      ></base-input>
                    </div>
                    <div class="col-md-4">
                      <base-input
                        type="text"
                        name="subDistrict"
                        required
                        label="แขวง/ตำบล"
                      ></base-input>
                    </div>
                    <div class="col-md-4">
                      <base-input
                        type="text"
                        name="district"
                        required
                        label="เขต/อำเภอ"
                      ></base-input>
                    </div>
                    <div class="col-md-4">
                      <base-input
                        type="text"
                        name="provice"
                        required
                        label="จังหวัด"
                      ></base-input>
                    </div>
                    <div class="col-md-4">
                      <base-input
                        type="text"
                        name="provice"
                        required
                        label="รหัสไปรษณีย์"
                      ></base-input>
                    </div>
                  </div>
                  --->
                  <div class="mb-3">
                    <h4 class="title">ช่องทางการชำระเงิน</h4>
                    <form
                      id="checkoutForm"
                      method="POST"
                      action="https://api-ft-staging.devfinite.solutions/v1/payment/charge"
                    >
                      <input type="hidden" name="omiseToken" />
                      <input type="hidden" name="omiseSource" />
                      <input type="hidden" name="omiseAmount" />
                      <input type="hidden" name="omiseDescription" />
                      <input type="hidden" name="customerId" />
                      <input type="hidden" name="paymentId" />
                      <base-button
                        type="submit"
                        class=" w-100  btn-info mt-2 mb-6"
                        id="checkout-button"
                        @click="handleSubmit()"
                      >
                        ชำระผ่านบัตรเครดิต
                      </base-button>
                      <!-- other payments
                      <base-button
                        type="submit"
                        class=" w-100 btn-info my-2"
                        id="pp-checkout-button"
                        value="ชำระผ่าน QR Code"
                      />
                      <base-button
                        type="submit"
                        class=" w-100 btn-info my-2"
                        id="kp-checkout-button"
                      />
                      -->
                    </form>
                    <a href="/cart" class="mt-3"
                      ><span class="fa fa-angle-left mr-2"></span> Return to
                      cart</a
                    >
                  </div>
                </div>
              </template>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as api from "@/services";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  bodyClass: "checkout-page",
  components: {},
  data() {
    return {
      errorMsg: "",
      customer: {
        name: "",
        email: "",
        phone:  "",
      },
    };
  },
  computed: {
    ...mapState("cart", [
      "cartStoreName",
      "cartItems",
      "cartStoreId",
      "cartStoreImage",
    ]),
    ...mapState("customer", [
      "userId",
      "displayName",
      "email",
      "metadata",
      "omiseId",
      "description",
    ]),
    ...mapGetters("cart", ["cartUniqueItemsCount", "isCartEmpty", "cartTotal"]),
  },
  methods: {
    ...mapMutations("customer", ["setOmiseId"]),
    ...mapActions("cart", ["removeCartItem"]),
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    removeItem(productId) {
      this.removeCartItem(productId);
    },
    isNumber: function(evt) {
      this.errorMsg = "";
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validateInput() {
      if (
        this.customer.name == "" ||
        this.customer.phone == "" ||
        this.customer.email == ""
      ) {
        this.errorMsg = "กรุณากรอกข้อมูลให้ครบทุกช่อง";
      } else if (
        !this.customer.email.match(
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        )
      ) {
        this.errorMsg = "กรุณากรอกอีเมลให้ถูกต้อง";
      } else if (!this.customer.phone.match(/([0]{1})+([869]{1})+[0-9]{8}/)) {
        this.errorMsg = "กรุณากรอกเบอร์โทรให้ถูกต้อง";
      } else {
        this.errorMsg = "";
      }
    },
    handleSubmit() {
      this.validateInput();
      if (!this.omiseId) {
        api
          .postCustomer({
            id: this.userId,
            email: this.customer.email,
            description: this.customer.name,
            metadata: {
              name: this.customer.name,
              phoneNumber: this.customer.phone.toString(),
            },
          })
          .then((res) => {
            this.setOmiseId(res.data.omiseId);
          })
          .catch((err) => console.log(err));
      } else {
        api
          .patchCustomer(this.userId, {
            email: this.customer.email,
            description: this.customer.name,
          })
          .then((res) => {
            this.setOmiseId(res.data.omiseId);
          })
          .catch((err) => console.log(err));
      }

      if (!this.errorMsg) {
        OmiseCard.open({
          amount: this.cartTotal * 100,
          currency: "THB",
          defaultPaymentMethod: "credit_card",
          frameLabel: this.cartStoreName,
          image: this.cartStoreImage,
          onCreateTokenSuccess: async (nonce) => {
            var form = document.querySelector("#checkoutForm");
            if (nonce.startsWith("tokn_")) {
              form.omiseToken.value = await nonce;
            } else {
              form.omiseSource.value = nonce;
            }

            const order = await api.createOmiseOrder({
              order: {
                customerId: this.userId,
                total: parseFloat(this.cartTotal),
                shopId: this.cartStoreId,
              },
              orderItems: this.cartItems,
              payment: {
                amount: parseFloat(this.cartTotal),
              },
            });

            form.omiseAmount.value = this.cartTotal * 100;
            form.omiseDescription.value =
              "Payment for Order ID: " + (await order.data.id);
            form.customerId.value = this.userId;
            form.paymentId.value = await order.data.paymentId;

            form.submit();
          },
        });
      }
    },
  },
  mounted() {
    OmiseCard.configure({
      publicKey: "pkey_test_5rcwdiusyef3ld9mtlu",
    });
    this.customer.name = this.description ? this.description : "";
    this.customer.email = this.email ? this.email : "";
    this.customer.phone = this.metadata ? this.metadata.phone : "";
  },
};
</script>
<style>
.checkout-page .nav.btn-group li {
  flex: 1 1 auto;
  padding: 0;
  text-align: center;
}

.checkout-page .nav.btn-group li a {
  padding: 0.25rem 0.5rem;
  margin: 0;
  box-shadow: none;
  text-transform: uppercase;
  border: 1px solid transparent;
}

.checkout-page .nav.btn-group li:first-child a {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.checkout-page .nav.btn-group li:last-child a {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.checkout-page .nav.btn-group li a:not(.active) {
  border: 1px solid #11cdef;
}
.error-input {
  color: #d8000c;
  background-color: #ffd2d2;
}
</style>
