<template>
  <section class="blogs-3">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 col-md-8 mx-auto">
          <div class="card card-blog card-plain blog-horizontal mb-5">
            <div class="row">
              <div class="col-lg-4">
                <div class="card-image shadow">
                  <a :href="link">
                    <img class="img rounded " :src="img_url" />
                  </a>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="card-body">
                  <h3 class="card-title">
                    <a :href="link" v-if="name">{{ name }}</a>
                    <p v-if="subtitle" class="mb-0">By {{ subtitle }}</p>
                  </h3>
                  <p class="card-description">
                    {{ description }}
                    <a v-if="link" :href="link"
                      >{{ linkText ? linkText : "Read More" }}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    name: String,
    subtitle: String,
    description: String,
    img_url: String,
    link: String,
    linkText: String,
  },
};
</script>
<style></style>
