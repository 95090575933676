<template>
    <modal :show="isShow" :showClose="false">
        <template v-slot:header>
            <h6 class="modal-title">ข้อกำหนดและเงื่อนไข</h6>
        </template>
        <p class=" overflow-auto vh-70">
            ข้อกำหนดและเงื่อนไขการใช้บริการเว็บไซต์
            บริษัท ซี.พี.แลนด์ จำกัด (มหาชน) ขอขอบพระคุณการเข้ามาเยี่ยมชมเว็บไซต์ https://catalogue.fortunetown.co.th
            การใช้บริการนี้ จะอยู่ภายใต้ข้อกำหนดและเงื่อนไขดังต่อไปนี้ ดังนั้น
            ขอความกรุณาท่านทำความเข้าใจข้อกำหนดและเงื่อนไขฉบับนี้โดยละเอียดก่อนการเข้าใช้งานบนเว็บไซต์
            โดยในบางครั้งบริษัทฯ
            อาจมีการเปลี่ยนแปลงเพิ่มเติม และการใช้งานของท่านจำเป็นต้องปฏิบัติตามในข้อตกลงนั้น
            ทุกครั้งที่มีการเปลี่ยนแปลง
        <ol>
            <li>
                การใช้บริการทางบริษัทฯ จัดให้มีเว็บไซต์นี้ขึ้น
                เพื่ออำนวยความสะดวกและเป็นผู้ให้บริการแก่สมาชิกและผู้ที่สนใจ
                จึงขอสงวนสิทธิ์ในการเปลี่ยนแปลงเนื้อหาและคุณสมบัติทางเทคนิคของเว็บไซต์ หรือการระงับ แก้ไข
                หรือข้อมูลใดก็ได้ตลอดเวลาตามที่บริษัทฯ เห็นสมควร
            </li>
            <li>
                ทรัพย์สินทางปัญญาเว็บไซต์นี้ ได้รับสิทธิ์ในลิขสิทธิ์ทางปัญญาในเนื้อหาต่าง ๆ ที่แสดงบนเว็บไซต์นี้ รวมถึง
                ข้อความ บทความ รูปภาพ เสียง กราฟฟิก หรือที่ครอบคลุม ที่เรียก ‘เนื้อหา’
                โดยทางบริษัทมีสิทธิ์ในการใช้เนื้อหานั้นเท่านั้น โดยมิอาจให้ใช้สู่การกระทำใดๆ อื่น
                ที่มิได้รับอนุญาตล่วงหน้าอย่างเป็นลายลักษณ์อักษรจากทางบริษัทฯ
            </li>
            <li>
                ข้อไม่รับประกันเนื้อหาที่ถูกแสดงบนเว็บไซต์ อ้างอิงข้อมูลมาจากหลายแหล่งฐานข้อมูล
                ผู้ใช้บริการพึงใช้วิจารณญาณในการตรวจสอบข้อมูลอีกขั้นอีกครั้ง และทางบริษัทฯ ไม่รับรองใด ๆ
                ว่าข้อมูลนั้นถูกต้องหรือครบถ้วนสมบูรณ์
            </li>
            <li>
                การลิงก์กับข้อมูลภายนอกการเชื่อมโยงไปยังเว็บไซต์อื่นทางบริษัท มิได้เป็นผู้รับผิดชอบในเว็บไซต์ ผลิตภัณฑ์
                หรือการบริการของเว็บบุคคลที่สามนั้น ทางบริษัทฯ ไม่มีความรับผิดชอบในเนื้อหาที่ปรากฏ หรือความเสียหายใดๆ
                ที่เกิดขึ้นจากการใช้ของเว็บไซต์เหล่านั้น
            </li>
            <li>
                การอนุญาตเข้าถึงทางบริษัทฯ สงวนสิทธิ์ ในการให้อนุญาต แก้ไขเว็บไซต์ หรือเข้าถึงเนื้อหา
                ในการใช้งานบนเว็บไซต์นี้ของบุคคลใด บุคคลอื่น โดยไม่ต้องมีการระบุเหตุผลในการดำเนินการนั้น
            </li>
            <li>
                ข้อจำกัดความรับผิดท่านรับทราบว่าบริษัท จะไม่รับผิดต่อความเสียหายใดๆ ไม่ว่าในสถานการณ์ใด
                ต่อท่านหรือต่อบุคคลอื่น ทั้งความเสียหายโดยตรงและทางอ้อม หรือค่าใช้จ่ายที่เกิดจากความล้มเหลวในการใช้งาน
                และไม่ต้องรับผิดชอบต่อการกระทำใดของผู้ใช้บริการทั้งหมดทั้งสิ้น
            </li>
            <li>
                การชดใช้ค่าเสียหายกรณีมีความเสียหายเกิดขึ้น ท่านตกลงที่ชดใช้ค่าเสียหายให้แก่ บริษัท ซี.พี.แลนด์ จำกัด
                (มหาชน)
                สำหรับการเรียกร้องความสูญเสีย ค่าใช้จ่าย หรือความเสียหายใดๆ (รวมทั้งค่าทนายและค่าใช้จ่ายในศาล)
                ที่เกิดขึ้นจากกรณี บริษัท ซี.พี.แลนด์ จำกัด (มหาชน) ได้ประสบหรือได้รับผลจากการใช้เว็บไซต์ของผู้ใช้งาน
            </li>
            <li>แนวทางปฏิบัติออนไลน์ท่านยินยอมที่จะใช้งานเว็บไซต์นี้เพื่อวัตถุประสงค์ที่เป็นไปตามที่ถูกกฎหมาย
                โดยมิยอมให้คุณโพสต์หรือส่งข้อมูลที่ผิดกฎหมาย เป็นอันตราย หมิ่นประมาท และมุ่งร้าย
                หรืออันใดที่ก่อให้เกิดความรับผิดทางแพ่งหรือทางอาญาภายใต้กฎหมายผ่านเว็บไซต์นี้</li>
            <li>กฎหมายที่บังคับใช้ข้อกำหนดและเงื่อนไขฯ นี้จะอยู่ภายใต้บังคับกฎหมายและการตีความ
                ภายใต้ข้อบังคับจากกฎหมายไทย
            </li>
        </ol>
        </p>
        <template v-slot:footer>
            <base-button type="primary" class="ml-auto" @click="isShow = false">Close</base-button>
        </template>
    </modal>
</template>
<script>
import Modal from "@/components/Modal.vue";
export default {
    components: {
        Modal,
    },
    data() {
        return {
            isShow: false
        }
    },
    methods: {
        openModal() {
            this.isShow = true;
        }
    }

}
</script>
<style scoped>
.vh-70 {
    height: 70vh;
}
</style>