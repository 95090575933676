<template>
  <div class="wrapper">
    <div
      class="page-header page-header-small "
    >
      <div
        class="page-header-image"
        style="background-image:url('https://fortunetown.co.th/wp-content/themes/plant/images/cover_homepage.png');"
      ></div>
      <div class="container pt-0">
        <div class="row">
          <div class="col-lg-6 col-md-7 mx-auto text-center">
            <h2 class="text-white">{{ shop.name }}</h2>
          </div>
        </div>
      </div>
     
    </div>
    <div class="container my-5" v-if="loading">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner
          style="width: 3rem; height: 3rem;"
          label="Loading..."
        ></b-spinner>
      </div>
      <p class=" text-center">กรุณารอสักครู่...</p>
    </div>
    <div v-else class="z-index-10000 container-5-3">
      <div class="section pt-0 pb-0">
        <div class="container">
          <h2 class="mb-3">สินค้าที่เข้าร่วมโปรโมชั่น</h2>
          <div class="row rounded shadow-sm px-2 mx-1 py-2">
            <div class=" d-flex flex-row">
              <img
                class=" rounded mr-2"
                width="75px"
                height="75px"
                :src="
                  promotion.profile_image_url
                    ? promotion.profile_image_url
                    : '/img/promotion_placeholder.jpg'
                "
              />

              <p class="line-height-1 mb-0">
                <strong>{{ promotion.name }}</strong> <br />
                <span class="limit-text">{{ promotion.description }}</span>
                <span v-if="promotion.startDate" class=" text-gray text-xs">
                  {{ convertDate(promotion.startDate) }} -
                  {{ convertDate(promotion.endDate) }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="section pb-0">
        <div class="container">
          <h2 class="mb-3">หมวดหมู่</h2>
          <div class=" row-scroll">
            <base-button
              outline
              type="primary"
              class=" rounded-pill py-1 px-2  no-wrap text-capitalize"
              size="md"
              @click="resetCategory()"
              >All Product</base-button
            >
            <base-button
              v-for="(item, index) in categories"
              :key="index"
              outline
              type="primary"
              class=" rounded-pill py-1 px-2  no-wrap text-capitalize"
              size="md"
              @click="setCategory(item.id)"
              >{{ item.name }}</base-button
            >
          </div>
        </div>
      </div>

      <div class="section pt-2">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div v-if="selectedCategoryId != null" class="row">
                <product-card
                  v-for="(item, index) in filteredProducts"
                  :key="index"
                  :itemImg="item.product_image_url"
                  :itemId="item.id"
                  :itemName="item.name"
                  :itemPrice="item.price"
                  :shopName="shop.name"
                  :shopId="shop.id"
                />
              </div>
              <div class="row" v-else>
                <product-card
                  v-for="(item, index) in products"
                  :key="index"
                  :itemId="item.id"
                  :itemImg="item.product_image_url"
                  :itemName="item.name"
                  :itemPrice="item.price"
                  :shopName="shop.name"
                  :shopId="shop.id"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VBTooltip } from "bootstrap-vue/esm/directives/tooltip/tooltip";
import "bootstrap-vue/dist/bootstrap-vue.min.css";
import * as api from "@/services";
import ProductCard from "./components/ProductCard.vue";
import { BSpinner } from "bootstrap-vue/esm/components/spinner/spinner";
export default {
  bodyClass: "ecommerce-page",
  components: {
    ProductCard,
    BSpinner
  },
  data() {
    return {
      categories: [],
      category: "",
      promotion: {},
      shop: {},
      products: [],
      filteredProducts: null,
      selectedCategoryId: null,
      productPromo: [],
      loading: true,
    };
  },
  directives: {
    BTooltip: VBTooltip,
  },
  methods: {
    prev() {
      this.$refs.myCarousel.prev();
    },
    next() {
      this.$refs.myCarousel.next();
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    monthToText(monthNum) {
      let month = [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ];
      return month[monthNum - 1];
    },
    convertDate(date) {
      let year = date.substring(0, 4);
      let month = date.substring(5, 7);
      let day = date.substring(8, 10);
      return day + "/" + month + "/" + year;
    },
    async setCategories() {
      for (const item of this.products) {
        if (!this.categories.some((x) => x.id === item.category.id)) {
          this.categories.push(item.category);
        }
      }
    },
    async setCategory(id) {
      this.selectedCategoryId = id;
      this.filteredProducts = this.products.filter((x) => x.categoryId == id);
    },
    async resetCategory() {
      this.selectedCategoryId = null;
      this.filteredProducts = [];
    },
  },
  created() {
    api
      .getPromotionById(this.$route.params.promotionId)
      .then((res) => {
        this.promotion = res.data.results[0];
        return res.data.results[0].shopId;
      })
      .then((res) => {
        api.getShopById(res).then((res) => {
          this.shop = res.data;
        });
      });

    api
      .getProductsPromotion(this.$route.params.promotionId)
      .then((res) => {
        this.productPromo = res.data.results;
        return res.data.results;
      })
      .then(async (res) => {
        for (let i = 0; i < res.length; i++) {
          await api.getProductById(res[i].productId).then(async (res) => {
            await this.products.push(res.data);
          });
        }
        return this.products;
      })
      .then((res) => {
        this.setCategories();
        this.loading = false;
      });
    
  },
};
</script>
<style>
.ecommerce-page a.carousel-control-prev,
.ecommerce-page a.carousel-control-next {
  z-index: 9999;
}
.z-index-10000 {
  z-index: 10;
}
.skew-separator:after {
  z-index: 0 !important;
}
</style>
<style scoped>
.sqr-img {
  width: 200px !important;
  height: 200px !important;
  object-fit: cover;
  border-radius: 50%;
}
.row-scroll {
  display: flex;
  display: -webkit-flex;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-flex-flow: row nowrap;
}
.row-scroll::-webkit-scrollbar {
  display: none;
}
.no-wrap {
  white-space: nowrap;
}
.line-height-1 {
  line-height: 1.5 !important;
}
.limit-text {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.text-xs {
  font-size: 0.8rem;
}
</style>
