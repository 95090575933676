<template>
  <div>
    <VueSlickCarousel
      :v-bind="settings"
      ref="carousel"
      :slidesToShow="isMobile() ? 1 : 4"
      :slidesToScroll="isMobile() ? 1 : 4"
      :autoplay="true"
      :autoplaySpeed="3000"
      v-if="products.length > 0"
      :dotsClass="'text-dark'"
    >
      <product-card
        v-for="(item, index) in products"
        :key="index"
        :itemImg="item.product_image_url"
        :itemId="item.id"
        :itemName="item.name"
        :itemPrice="item.price"
        :shopName="item.shop.name"
        :shopId="item.shopId"
        :col="1"
        :discount="item.discount ? item.discount.discount_percent : null" 
      />
    </VueSlickCarousel>
    <a
      class="carousel-control-prev"
      v-on:click="prev"
      role="button"
      data-slide="prev"
    >
      <button
        type="button"
        class="btn btn-icon  btn-xl  shadow-none"
        name="button"
      >
        <i class="ni ni-bold-left  text-dark shadow-none"></i>
      </button>
    </a>
    <a
      class="carousel-control-next"
      v-on:click="next"
      role="button"
      data-slide="next"
    >
      <button
        type="button"
        class="btn btn-icon btn-xl shadow-none"
        name="button"
      >
        <i class="ni ni-bold-right text-dark"></i>
      </button>
    </a>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import ProductCard from "./ProductCard.vue";
import { VBTooltip } from "bootstrap-vue/esm/directives/tooltip/tooltip";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "MyComponent",
  components: { VueSlickCarousel, ProductCard },
  data() {
    return {
      cartAlert: false,
      products1: {},
      products2: {},
      events: [],
      promotions: [],
      shop_promotions: {},
      loading: true,
      settings: {
        dots: true,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    };
  },
  props: {
    products: {
      required: true,
    },
  },
  directives: {
    BTooltip: VBTooltip,
  },
  methods: {
    prev() {
      this.$refs.carousel.prev();
    },
    next() {
      this.$refs.carousel.next();
    },
    beforeUpdate() {
      if (this.$refs.carousel) {
        this.$refs.carousel.destroy();
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    updated() {
      this.$nextTick(function() {
        if (this.$refs.carousel) {
          this.$refs.carousel.create(this.settings);
        }
      });
    },
  },
};
</script>
