<template>
    <modal :show="isShow" :showClose="false">
        <template v-slot:header>
            <h6 class="modal-title">นโยบายความเป็นส่วนตัว</h6>
        </template>
        <p class=" overflow-auto vh-70" style="white-space: pre-wrap!important;">
            บริษัท ซี.พี.แลนด์ จำกัด (มหาชน) และนิติบุคคลใดๆ ที่บริษัทเป็นผู้ถือหุ้นข้างมากไม่ว่าโดยตรงหรือ
            โดยอ้อมในนิติบุคคลนั้น หรือบริษัทมีอำนาจควบคุมหรือบริหารไม่ว่าโดยการเป็นกรรมการเสียงข้างมากในนิติบุคคลนั้น
            หรือโดยสัญญา หรือโดยประการอื่น (ต่อไปนี้จะเรียกว่า “กลุ่มบริษัท”) ตระหนักถึงความสำคัญของประเด็นการคุ้มครอง
            ข้อมูลส่วนบุคคล นโยบายนี้อธิบายถึงวิธีการที่บริษัทและ/หรือกลุ่มบริษัท ปฏิบัติต่อข้อมูลส่วนบุคคลของท่าน เช่น
            การเก็บรวบรวม การจัดเก็บรักษา การใช้ การเปิดเผย รวมถึงสิทธิต่างๆ ของท่าน เป็นต้น เราจึงได้จัดทำนโยบาย
            ความเป็นส่วนตัว ซึ่งครอบคลุมถึงวิธีที่เราเก็บรวบรวม ใช้ เปิดเผย ถ่ายโอน และจัดเก็บข้อมูลของคุณ โปรดสละเวลา
            สักครู่เพื่อทำความเข้าใจแนวทางปฏิบัติด้านความเป็นส่วนตัวของเรา

            <br /><br /><strong>การเคารพสิทธิในความเป็นส่วนบุคคลของผู้ใช้บริการ</strong>
        <ol>
            <li>
                บริษัทและ/หรือกลุ่มบริษัทเคารพและให้ความสำคัญ
                ถึงสิทธิข้อมูลส่วนบุคคลและการคุ้มครองข้อมูลส่วนบุคคลของท่านและตระหนักดีว่าท่านในฐานะผู้ใช้บริการของบริษัทและ/หรือกลุ่มบริษัทย่อมมีความประสงค์ที่จะได้รับความมั่นคงปลอดภัยในการใช้บริการ
            </li>
            <li>
                ข้อมูลส่วนบุคคลของท่านที่บริษัทและ/หรือกลุ่มบริษัทได้รับมา เช่น ชื่อ อายุ ที่อยู่ หมายเลขโทรศัพท์
                หมายเลขบัตรประชาชน ข้อมูลทางการเงิน เป็นต้น ซึ่งสามารถบ่งบอกตัวบุคคลของท่านได้
                และเป็นข้อมูลส่วนบุคคลที่มีความสมบูรณ์ ถูกต้อง เป็นปัจจุบัน และมีคุณภาพ
                จะถูกนำไปใช้ให้เป็นไปตามวัตถุประสงค์การดำเนินงานของบริษัทและ/หรือกลุ่มบริษัท เท่านั้น
                โดยจะดำเนินมาตรการที่เข้มงวดในการรักษาความมั่นคงปลอดภัย
                ตลอดจนการป้องกันมิให้มีการนำข้อมูลส่วนบุคคลไปใช้โดยมิได้รับอนุญาตจากท่านก่อน ทั้งนี้
                บริษัทและ/หรือกลุ่มบริษัทจะแจ้งเหตุการละเมิดข้อมูลส่วนบุคคลแก่ท่านทันทีที่เกิดเหตุละเมิด
            </li>
        </ol>
        <br /><strong>การเก็บรวบรวมข้อมูลส่วนบุคคลอย่างจำกัด</strong>
        <ol>
            <li>
                ในการเก็บรวบรวมข้อมูล และเก็บรักษาข้อมูลส่วนบุคคลที่มีคุณภาพของท่าน
                บริษัทและ/หรือกลุ่มบริษัทจะใช้วิธีการที่ชอบด้วยกฎหมายและเป็นธรรมในการเก็บรวบรวมและจัดเก็บข้อมูล
                ตลอดจนเก็บรวบรวม และจัดเก็บข้อมูลส่วนบุคคล
                อย่างจำกัดเพียงเท่าที่จำเป็นแก่การให้บริการธุรกรรมทางอิเล็กทรอนิกส์
                หรือบริการด้วยวิธีการทางอิเล็กทรอนิกส์อื่นใดภายใต้วัตถุประสงค์ของบริษัทและ/หรือกลุ่มบริษัทเท่านั้น
            </li>
            <li>
                บริษัทและ/หรือกลุ่มบริษัทจะขอความยินยอมจากท่านก่อนทำการเก็บรวบรวม เว้นแต่
                <ul>
                    <li>
                        กรณีที่กฎหมายกำหนด
                    </li>
                    <li>
                        เป็นไปเพื่อประโยชน์ของท่าน และการขอความยินยอมไม่อาจกระทำได้ในเวลานั้น
                    </li>
                    <li>
                        เป็นไปเพื่อประโยชน์เกี่ยวกับชีวิต สุขภาพ หรือความปลอดภัยของท่านและผู้ใช้บริการท่านอื่น
                    </li>
                    <li>
                        เพื่อประโยชน์แก่การสอบสวนของพนักงานสอบสวน หรือการพิจารณาพิพากษาคดีของศาล
                    </li>
                    <li>
                        เพื่อประโยชน์ในการศึกษา วิจัย หรือการจัดทำสถิติ
                    </li>
                </ul>
            </li>
            <li>
                <pre></pre>
                บริษัทและ/หรือกลุ่มบริษัทจะไม่จัดเก็บข้อมูลส่วนบุคคลของท่านซึ่งเกี่ยวกับลักษณะทางพันธุกรรม
                พฤติกรรมทางเพศ หรือข้อมูลที่อาจเป็นผลร้าย ทำให้เสียชื่อเสียง
                หรืออาจก่อให้เกิดความรู้สึกเกี่ยวกับการเลือกปฏิบัติโดยไม่เป็นธรรมหรือความไม่เท่าเทียมกันแก่บุคคลใด
                เว้นแต่
                <ul>
                    <li>
                        ได้รับความยินยอมเป็นหนังสือจากท่าน
                    </li>
                    <li>
                        เป็นกรณีที่กฎหมายกำหนด
                    </li>
                    <li>
                        เป็นไปเพื่อประโยชน์ของท่าน และการขอความยินยอมไม่อาจกระทำได้ในเวลานั้น
                    </li>
                    <li>
                        เป็นไปเพื่อประโยชน์เกี่ยวกับชีวิต สุขภาพ หรือความปลอดภัยของท่านและผู้ใช้บริการท่านอื่น
                    </li>
                    <li>
                        เพื่อประโยชน์แก่การสอบสวนของพนักงานสอบสวน หรือการพิจารณาพิพากษาคดีของศาล
                    </li>
                    <li>
                        เพื่อประโยชน์ในการศึกษา วิจัย หรือการจัดทำสถิติ
                    </li>
                </ul>
            </li>
            <li>
                บริษัทและ/หรือกลุ่มบริษัทอาจรวบรวมข้อมูลส่วนบุคคลของท่าน
                เข้ากับข้อมูลส่วนบุคคลของท่านที่ได้รับมาจากแหล่งอื่นเฉพาะในกรณีที่มีความจำเป็นและได้รับความยินยอมจากท่านเท่านั้น
                ทั้งนี้ เพื่อประโยชน์ในการปรับปรุงข้อมูลส่วนบุคคลของท่านให้เป็นปัจจุบัน
                และเพื่อปรับปรุงคุณภาพและประสิทธิภาพของการให้บริการของบริษัทและ/หรือกลุ่มบริษัทดียิ่งขึ้น
            </li>
        </ol>
        <br /> <strong>การนำข้อมูลส่วนบุคคลไปใช้อย่างจำกัด</strong>
        <ol>
            <li>
                บริษัทและ/หรือกลุ่มบริษัทจะใช้เปิดเผยข้อมูลส่วนบุคคลของท่านได้
                ต่อเมื่อได้รับความยินยอมจากท่านและจะต้องเป็นการใช้ตามวัตถุประสงค์ของบริษัทและ/หรือกลุ่มบริษัทเท่านั้น
            </li>
            <li>บริษัทและ/หรือกลุ่มบริษัทจะดูแลให้ผู้ปฏิบัติงานของสำนักงานมิให้เปิดเผย แสดง
                หรือทำให้ปรากฏในลักษณะอื่นใดซึ่งข้อมูลส่วนบุคคลของท่านนอกเหนือไปจากวัตถุประสงค์หรือต่อบุคคลภายนอก
                เว้นแต่
                <ul>
                    <li>
                        เป็นกรณีที่กฎหมายกำหนด
                    </li>
                    <li>
                        ได้รับความยินยอมจากท่าน
                    </li>
                    <li>
                        เป็นไปเพื่อประโยชน์เกี่ยวกับชีวิต สุขภาพ หรือความปลอดภัยของท่านและผู้ใช้บริการอื่น
                    </li>
                    <li>
                        เพื่อประโยชน์แก่การสอบสวนของพนักงานสอบสวน หรือการพิจารณาพิพากษาคดีของศาล
                    </li>
                    <li>
                        เพื่อประโยชน์ในการศึกษา วิจัย หรือการจัดทำสถิติ
                    </li>
                </ul>
            </li>
        </ol>
        ในบางกรณีบริษัทและ/หรือกลุ่มบริษัท อาจให้บุคคลหรือหน่วยงานอื่นเข้าถึงหรือใช้ข้อมูลส่วนบุคคลของท่าน เท่าที่จำเป็น
        และเพื่อให้เป็นไปตามวัตถุประสงค์และอำนาจหน้าที่ของบริษัทและ/หรือกลุ่มบริษัท ทั้งนี้
        บริษัทและ/หรือกลุ่มบริษัทจะต้องได้รับความยินยอมจากท่านก่อน<br />
        <br /><strong>การรักษาความมั่นคงปลอดภัย</strong><br />
        บริษัทและ/หรือกลุ่มบริษัทตระหนักถึงความสำคัญของการรักษาความมั่นคงปลอดภัย ของข้อมูลส่วนบุคคลของท่าน
        บริษัทและ/หรือกลุ่มบริษัทจึงกำหนดให้มีมาตรการ ในการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล
        อย่างเหมาะสมและสอดคล้องกับการรักษาความลับของข้อมูลส่วนบุคคลเพื่อป้องกันการสูญหาย การเข้าถึง ทำลาย ใช้ แปลง
        แก้ไขหรือเปิดเผยข้อมูลส่วนบุคคลโดยไม่มีสิทธิหรือโดยไม่ชอบด้วยกฎหมาย
        โดยให้เป็นไปตามที่กำหนดในนโยบายและแนวปฏิบัติในการรักษาความมั่นคงปลอดภัยด้านเทคโนโลยีสารสนเทศของบริษัทและ/หรือกลุ่มบริษัท<br />
        <br /><strong>การมีส่วนร่วมของเจ้าของข้อมูลส่วนบุคคล</strong>
        <ol>
            <li>
                ในกรณีที่ท่านประสงค์จะทราบข้อมูลส่วนบุคคลเกี่ยวกับตนเอง
                ท่านสามารถมีคำร้องขอตามหลักเกณฑ์และวิธีการที่บริษัทและ/หรือกลุ่มบริษัทกำหนด
                เมื่อบริษัทและ/หรือกลุ่มบริษัทได้รับคำร้องขอดังกล่าวแล้ว
                บริษัทและ/หรือกลุ่มบริษัทจะรีบดำเนินการแจ้งถึงความมีอยู่
                หรือรายละเอียดของข้อมูลส่วนบุคคลดังกล่าวให้ท่านภายในระยะเวลาอันสมควร
            </li>
            <li>
                หากท่านเห็นว่า ข้อมูลส่วนบุคคลใดที่เกี่ยวกับตนไม่ถูกต้องตามที่เป็นจริง
                ท่านสามารถแจ้งบริษัทและ/หรือกลุ่มบริษัทเพื่อให้แก้ไข เปลี่ยนแปลง หรือลบข้อมูลส่วนบุคคลนั้นได้ ในการนี้
                บริษัทและ/หรือกลุ่มบริษัทจะจัดทำบันทึกคำคัดค้านการจัดเก็บ ความถูกต้อง หรือการกระทำใด ๆ
                เกี่ยวกับข้อมูลส่วนบุคคลของท่านไว้เป็นหลักฐานด้วย
            </li>
            <li>
                ท่านมีสิทธิตรวจดูความมีอยู่ ลักษณะของข้อมูลส่วนบุคคล วัตถุประสงค์ของการนำข้อมูลไปใช้
                และสถานที่ทำการของบริษัทและ/หรือกลุ่มบริษัท นอกจากนี้ ยังมีสิทธิดังต่อไปนี้
                <ul>
                    <li>ขอสำเนา หรือขอสำเนารับรองถูกต้องเกี่ยวกับข้อมูลส่วนบุคคลของตน</li>
                    <li>ขอแก้ไข หรือเปลี่ยนแปลงข้อมูลส่วนบุคคลของตนให้ถูกต้องสมบูรณ์</li>
                    <li>ขอระงับการใช้ หรือเปิดเผยข้อมูลส่วนบุคคลที่เกี่ยวกับตน</li>
                    <li>ขอให้ดำเนินการลบ หรือทำลายข้อมูลส่วนบุคคลที่เกี่ยวกับตน</li>
                    <li>ขอให้เปิดเผยถึงการได้มาซึ่งข้อมูลส่วนบุคคลที่เกี่ยวกับตน
                        ในกรณีที่เป็นข้อมูลซึ่งผู้ใช้บริการไม่ได้ให้ความยินยอมในการรวบรวมหรือจัดเก็บ</li>
                </ul>
            </li>
        </ol>
        อย่างไรก็ตาม บริษัทและ/หรือกลุ่มบริษัทอาจปฏิเสธสิทธิของท่านได้ในกรณีที่กฎหมายกำหนด
        หรือในกรณีที่ข้อมูลส่วนบุคคลของท่านถูกทำให้ไม่ปรากฏชื่อหรือสิ่งบอกลักษณะอันสามารถระบุตัวท่านได้อีก<br />
        <br /><strong>
            การเปลี่ยนแปลงนโยบายส่วนบุคคล
        </strong><br />
        บริษัทและ/หรือกลุ่มบริษัทอาจปรับปรุงนโยบายส่วนบุคคลนี้เป็นครั้งคราวเพื่อให้สอดคล้องกับการเปลี่ยนแปลงของการให้บริการ
        การดำเนินงานของบริษัทและ/หรือกลุ่มบริษัท รวมถึงข้อแสนอแนะและความคิดเห็นจากท่าน โดย
        บริษัทและ/หรือกลุ่มบริษัทจะประกาศแจ้งการเปลี่ยนแปลงให้ทราบอย่างชัดเจน ก่อนจะเริ่มดำเนินการเปลี่ยนแปลง
        หรืออาจส่งประกาศแจ้งเตือนให้ท่านทราบโดยตรง
        </p>
        <template v-slot:footer>
            <base-button type="primary" class="ml-auto" @click="isShow = false">Close</base-button>
        </template>
    </modal>
</template>
<script>
import Modal from "@/components/Modal.vue";
export default {
    components: {
        Modal,
    },
    data() {
        return {
            isShow: false
        }
    },
    methods: {
        openModal() {
            this.isShow = true;
        }
    }

}
</script>
<style scoped>
.vh-70 {
    height: 70vh;
}
</style>