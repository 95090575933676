import axios from "../axios";

function getProducts() {
  return axios.get("/products");
}
function getProductsByShopId(id) {
  return axios.get("/products?shopId=" + id);
}
function getProductById(id) {
  return axios.get("/products/" + id);
}
function getProductsSortById(id) {
  return axios.get("products?sort_by=id.desc");
}
export { getProducts, getProductsByShopId, getProductById, getProductsSortById };
