import axios from "../axios";
function postCustomer(data) {
  return axios.post("/customers", data);
}

function getCustomer(id) {
  return axios.get("/customers/" + id);
}

function patchCustomer(id, data) {
  return axios.patch("/customers/" + id, data);
}
function getCustomerByLineUid(id) {
  return axios.get(`/customers?id=${id}`);
}

export { postCustomer, getCustomer, patchCustomer, getCustomerByLineUid };
