<template>
  <div class="wrapper">
    <div class="section-shaped my-0 skew-separator skew-mini">
      <div class="page-header page-header-small header-filter">
        <div
          class="page-header-image"
          style="background-image: url('img/pages/nathan-dumlao.jpg');"
        ></div>
        <div class="container">
          <div class="header-body text-center mb-7">
            <div class="row justify-content-center">
              <div class="col-xl-5 col-lg-6 col-md-8 px-5">
                <h1 class="text-white">Thank You</h1>
                <p class="text-lead text-white">
                  กรุณาเซฟหน้านี้ไว้เพื่อใช้ในการรับสินค้า
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="upper">
      <div class="container">
        <div class="row">
          <div class="col-md-10 mx-auto">
            <div class="card card-invoice">
              <div class="card-header text-center">
                <div class="row justify-content-between">
                  <div class="col-md-4 text-left">
                    <img
                      class="mb-2 w-100"
                      :src="shop.profile_image_url"
                      alt="Logo"
                    />
                    <h6>
                      {{ shop.name }}
                    </h6>
                    <small class="d-block text-muted">{{
                      shop.phoneNumber
                    }}</small>
                  </div>
                  <div class="col-lg-3 col-md-5 text-left mt-3">
                    <h4 class="mb-1">Billed to:</h4>
                    <span class="d-block">{{ customer.description }}</span>
                    <p>
                      {{ customer.email }}<br />
                      {{ customer.phoneNumber }},<br />
                    </p>
                  </div>
                </div>
                <br />
                <div class="row justify-content-md-between">
                  <div class="col-md-4">
                    <h3 class="mt-3 text-left">
                      Invoice no <br /><small class="mr-2"
                        >#{{ orderId }}</small
                      >
                    </h3>
                  </div>
                  <div class="col-lg-4 col-md-5">
                    <div class="row mt-5">
                      <div class="col-md-6">Invoice date:</div>
                      <div class="col-md-6">{{ date }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="table-responsive">
                      <table class="table text-right">
                        <thead class="bg-default">
                          <tr>
                            <th scope="col" class="text-right text-white">
                              ชื่อสินค้า
                            </th>
                            <th scope="col" class="text-right text-white">
                              จำนวน
                            </th>
                            <th scope="col" class="text-right text-white">
                              ราคารวม
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in orderItemsUpdate" :key="index">
                            <td>{{ item.name }}</td>
                            <td>{{ item.quantity }}</td>
                            <td>{{numberWithCommas(item.price * item.quantity) }}</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th class="h4">Total</th>
                            <th colspan="3" class="text-right h4">
                              {{ numberWithCommas(total) }} บาท
                            </th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer text-right">
                <div class="col-md-5 ml-auto">
                  <h5>Thank you!</h5>
                  <p class="description">
                    หากพบปัญหา ติดต่อ:
                  </p>
                  <h6 class="d-block">
                    email:
                    <small class="text-muted"
                      >contact@devfinite.solutions</small
                    >
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 ml-auto mb-5"></div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import * as api from "@/services";
import { mapState } from "vuex";
export default {
  bodyClass: "invoice-page",
  data() {
    return {
      orderId: "",
      customerId: "",
      shopId: "",
      shop: {},
      customer: {},
      payment: {},
      orderItems: [],
      total: 0,
      date: "",
      orderItemsUpdate:[]
    };
  },
  computed: {},
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    printPage() {
      this.print = true;
      window.print();
      //this.print=false
    },
  },

  mounted() {
    this.orderId = this.$route.query.order;
    this.customerId = this.$route.query.customer;
    api
      .getOrderById(this.orderId)
      .then((res) => {
        this.shopId = res.data.shopId;
        this.customer = res.data.customer;
        this.payment = res.data.payment;
        this.orderItems = res.data.order_items;
        this.date = res.data.created_at;
        this.date = this.date.substring(0, 10);
        this.total = res.data.total;
      })
      .then(() => {
        if (this.customerId != this.customer.id) {
          this.$router.push({ path: "/error" });
        }
      })
      .then(() => {
        api.getShopById(this.shopId).then((res) => {
          this.shop = res.data;
        });
      })
      .then((res) => {
       
        for (let i=0; i < this.orderItems.length; i++) {
          api.getProductById(this.orderItems[i].productId).then((res) => {
            this.orderItems[i].name = res.data.name;
            this.orderItems[i].price = res.data.price;
            this.orderItemsUpdate.push(this.orderItems[i])
          }).catch(err=>console.log(err))
        }
      });
  },
};
</script>
<style></style>
