import Vue from "vue";
import Vuex from "vuex"

//plugins
import createPersistedState from 'vuex-persistedstate'
//module
import customer from './module/customer'
import cart from './module/cart'
import global from './module/global'

Vue.use(Vuex);
export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    modules: {
        customer,
        cart,
        global
    },
    state: {},
    getters: {},
    mutations: {

    },
    actions: {
        reset({commit}){
            commit('form/RESET_STATE', null, { root: true })
            commit('auth/RESET_STATE',null,{root:true})
        },
    },
})


