<template>
  <header class="header-global" v-if="userId || !isLineClient">
    <base-nav
      class="navbar-main headroom"
      id="navbar-main"
      :type="navbarType ? navbarType : 'default'"
      effect="dark"
      expand
    >
      <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
        <img
          src="https://fortunetown.co.th/wp-content/uploads/2021/08/cropped-Group-133.png"
          alt="logo"
        />
      </router-link>
      <div class="row" slot="content-header" slot-scope="{ closeMenu }">
        <div class="col-6 collapse-brand">
          <a href="javascript:void(0)"></a>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto">
        <base-dropdown class="nav-item">
          <a
            role="button"
            slot="title"
            href="#"
            class="nav-link"
            data-toggle="dropdown"
          >
            <i class="ni ni-shop d-lg-none"></i>
            <span class="nav-link-inner--text">Stores</span>
          </a>
          <router-link
            v-for="(item, index) in SHOP_CATEGORIES"
            :key="index"
            :to="'/categories/' + item.id"
            class="dropdown-item"
          >
            {{ item.name }}
          </router-link>
        </base-dropdown>

        <a role="button" slot="title" href="/events" class="nav-link">
          <i class="ni ni-favourite-28 d-lg-none"></i>
          <span class="nav-link-inner--text">Events</span>
        </a>
        <a role="button" slot="title" href="/promotions" class="nav-link">
          <i class="ni ni-tag d-lg-none"></i>
          <span class="nav-link-inner--text">Promotions</span>
        </a>
      </ul>
    </base-nav>
  </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import CloseButton from "@/components/CloseButton";
import BaseDropdown from "@/components/BaseDropdown";
import Headroom from "headroom.js";
import { mapState } from "vuex";

export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown,
  },
  props: {
    navbarType: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("global", ["SHOP_CATEGORIES"]),
    ...mapState("customer", ["userId", "isLineClient"]),
  },
  mounted: function() {
    let headroom = new Headroom(document.getElementById("navbar-main"), {
      offset: 300,
      tolerance: {
        up: 10,
        down: 10,
      },
    });
    headroom.init();
  },
};
</script>
<style>
.navbar-main.headroom {
  z-index: 9999;
}
.headroom--top {
  position: relative !important;
}
.headroom--pinned .headroom--top {
  position: relative !important;
}
.header-global .headroom--pinned {
  position: relative !important;
}
</style>
