import axios from "../axios";
import * as productAPI from "./Product";
import * as inventoryAPI from "./ProductInventory";

// creat order -> create order items -> update inventory -> create payment -> patch order with payment id
function createOmiseOrder(data) {
  let order = data.order;
  let orderItems = data.orderItems;
  let payment = data.payment;
  const promise = new Promise((resolve, reject) => {
    postOrder(order)
      .then(async (res) => {
        orderItems.forEach((item) => {
          const orderItem = {};
          orderItem.productId = item.productId;
          orderItem.quantity = item.quantity;
          orderItem.orderId = res.data.id;
          postOrderItems(orderItem);
        });

        const orderId = res.data.id;
        payment.orderId = orderId;
        payment.status = "Pending";
        payment.provider = "Omise";
        postPayment(payment).then((res) => {
          patchOrder({ paymentId: res.data.id }, orderId).then((res) => {
            resolve(res);
          });
        });
      })
      .catch((err) => reject(err));
  });

  return promise;
}

function postOrder(data) {
  return axios.post("/orders", data);
}

function patchOrder(data, id) {
  return axios.patch("/orders/" + id, data);
}
function postOrderItems(data) {
  return axios.post("/order-items", data);
}

function postPayment(data) {
  return axios.post("/payment", data);
}

export { createOmiseOrder, postOrder, postOrderItems, postPayment, patchOrder };
