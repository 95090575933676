var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"page-header page-header-small "},[_c('div',{staticClass:"page-header-image",staticStyle:{"background-image":"url('https://fortunetown.co.th/wp-content/themes/plant/images/cover_homepage.png')"}}),_c('div',{staticClass:"container pt-0 "},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-7 mx-auto text-center"},[_c('h1',{staticClass:"title text-white"},[_vm._v(_vm._s(_vm.shopName))])])])])]),(_vm.loading)?_c('div',{staticClass:"container my-5"},[_c('div',{staticClass:"d-flex justify-content-center mb-3"},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"label":"Loading..."}})],1),_c('p',{staticClass:" text-center"},[_vm._v("กรุณารอสักครู่...")])]):_c('div',{staticClass:"z-index-10000 my-6 "},[_c('div',{staticClass:"pt-0 pb-0"},[(_vm.promotions.length != 0)?_c('div',{staticClass:"container"},[_c('h2',{staticClass:"mb-3"},[_vm._v("โปรโมชั่น")]),_vm._l((_vm.promotions),function(item,index){return _c('div',{key:index,class:[
          item.id == _vm.active_promo ? 'bg-primary' : '',
          'row rounded shadow-sm px-2 mx-1 py-2 cursor-pointer' ],on:{"click":function($event){return _vm.setPromo(item.id)}}},[_c('div',{staticClass:" d-flex flex-row "},[_c('img',{staticClass:" rounded mr-2",attrs:{"width":"75px","height":"75px","src":item.promotion_image_url
                ? item.promotion_image_url
                : '/img/promotion_placeholder.jpg'}}),_c('p',{class:[
              item.id == _vm.active_promo ? 'text-white' : '',
              'line-height-1 mb-0' ]},[_c('strong',[_vm._v(_vm._s(item.name))]),_vm._v(" "),_c('br'),_c('span',{staticClass:"limit-text text-xs"},[_vm._v(_vm._s(item.description))]),_c('br'),(item.startDate)?_c('span',{class:[
                item.id == _vm.active_promo ? '' : 'text-gray ',
                'text-xs' ]},[_vm._v(" "+_vm._s(_vm.convertDate(item.startDate))+" - "+_vm._s(_vm.convertDate(item.endDate))+" ")]):_vm._e()])])])})],2):_vm._e()]),_c('div',{staticClass:"section pt-4 pb-0"},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"mb-3"},[_vm._v("เลือกหมวดหมู่สินค้า")]),_c('div',{staticClass:" row-scroll"},[_c('base-button',{staticClass:" rounded-pill py-1 px-2  no-wrap text-capitalize",attrs:{"outline":"","type":"primary","size":"md"},on:{"click":function($event){return _vm.setCategory(undefined)}}},[_vm._v("All Product")]),_vm._l((_vm.categories),function(item,index){return _c('base-button',{key:index,class:[
            item.id == _vm.selectedCategoryId ? 'bg-primary text-white' : '',
            'rounded-pill py-1 px-2  no-wrap text-capitalize' ],attrs:{"outline":"","type":"primary","size":"md"},on:{"click":function($event){return _vm.setCategory(item.id)}}},[_vm._v(_vm._s(item.name))])})],2)])]),_c('div',{staticClass:"section pt-2"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(!_vm.filteredProducts && (_vm.selectedCategoryId || _vm.active_promo))?_c('div',{staticClass:"row"},[_c('p',{staticClass:" my-5 py-5 text-center w-100 "},[_vm._v("ไม่พบสินค้า")])]):_vm._e(),(_vm.filteredProducts)?_c('div',{staticClass:"row"},_vm._l((_vm.filteredProducts),function(item,index){return _c('product-card',{key:index,attrs:{"itemImg":item.product_image_url,"itemId":item.id,"itemName":item.name,"itemPrice":item.price,"shopName":_vm.shopName,"shopId":_vm.shopId,"quantity":item.inventory.quantity,"discount":item.discount ? item.discount.discount_percent : null}})}),1):_c('div',{staticClass:"row"},_vm._l((_vm.products),function(item,index){return _c('product-card',{key:index,attrs:{"itemId":item.id,"itemImg":item.product_image_url,"itemName":item.name,"itemPrice":item.price,"shopName":_vm.shopName,"shopId":_vm.shopId,"quantity":item.inventory.quantity,"discount":item.discount ? item.discount.discount_percent : null}})}),1)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }