<template>
  <section class="wrapper" style="background-color:rgba(53,181,227,0.15)">
    <div class="my-card">
      <img src="img/gif/droll-shopping.gif" width="70%"
        style="max-width:300px; margin-bottom: 48px;margin-top: -96px;" />
      <div class="loading">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  bodyClass: "ecommerce-page",
  components: {},
  data() { },
  directives: {},
  computed: {},
  methods: {},
};
</script>
<style scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
}

.wrapper .my-card {
  display: flex;
  padding: 24px;
  border-radius: 5px;
  min-height: 300px;
  min-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.wrapper .my-card .loading {
  position: relative;
  height: 0;
  width: 0;
}

.wrapper .my-card .loading span {
  display: block;
  height: 20px;
  width: 20px;
  background: rgb(53, 181, 227);
  position: absolute;
  border-radius: 50%;
}

.wrapper .my-card .loading span:nth-of-type(1) {
  left: -40px;
  animation: loader1Anim 1.2s linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: forwards;
}

.wrapper .my-card .loading span:nth-of-type(2) {
  left: -10px;
  animation: loader1Anim 1.2s linear;
  animation-delay: 0.2s;
  animation-iteration-count: infinite;
  animation-direction: forwards;
}

.wrapper .my-card .loading span:nth-of-type(3) {
  left: 20px;
  animation: loader1Anim 1.2s linear;
  animation-delay: 0.4s;
  animation-iteration-count: infinite;
  animation-direction: forwards;
}

@keyframes loader1Anim {

  from,
  to {
    transform: scale3d(1.15, 0.8, 1);
  }

  42% {
    transform: translateY(-45px);
  }

  88% {
    transform: scale3d(1, 1, 1);
  }

  95% {
    transform: scale3d(1.05, 0.85, 1);
  }

  98% {
    transform: scale3d(1.15, 0.8, 1);
  }
}
</style>
