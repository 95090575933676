<template>
  <div class="wrapper">
    <div class="page-header page-header-small header-filter">
      <div
        class="page-header-image"
        style="background-image: url('https://fortunetown.co.th/wp-content/themes/plant/images/cover_homepage.png');"
      ></div>
      <div class="content-center">
        <div class="row">
          <div class="col-md-6 mx-auto text-center">
            <h1 class="title text-white">
              Events 
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div class="main mt-4">
      <div class="container" v-if="loading">
        <div class="d-flex justify-content-center mb-3">
          <b-spinner
            style="width: 3rem; height: 3rem;"
            label="Loading..."
          ></b-spinner>
        </div>
        <p class=" text-center">กรุณารอสักครู่...</p>
      </div>
      <div v-else class="container mt-6 ">
        <br />
        <p v-if="Events.length==0" class=" text-center">ขออภัย ขณะนี้ไม่มีอีเว้นท์...</p>
        <template v-else>
          <Blog
            v-for="(item, index) in Events"
            :key="index"
            :name="item.name ? item.name : ''"
            :description="item.description"
            :img_url="
              item.event_img_url
                ? item.event_img_url
                : '/img/promotion_placeholder.jpg'
            "
            :link="item.link"
            subtitle=""
          ></Blog>
        </template>
        <br />
        <br />
      </div>
    </div>
  </div>
</template>
<script>
import Blog from "./widgets/EventThumbnail.vue";
import * as api from "@/services";
import { BSpinner } from "bootstrap-vue/esm/components/spinner/spinner";
export default {
  components: {
    Blog,
    BSpinner,
  },
  data() {
    return {
      promotions: [],
      shop_promotions: [],
      loading: true,
    };
  },
  async created() {
    window.location.href = "https://fortunetown.co.th/happenings/";
    await api.getEventsByDate(10,1).then(async (res) => {
      this.Events = await res.data.results.slice(0, 4);
    });

    this.loading = await false;
  },
};
</script>
<style></style>
