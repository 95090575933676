<template>
  <div class="wrapper">
    <div class="page-header page-header-small ">
      <div class="page-header-image"
        style="background-image:url('https://fortunetown.co.th/wp-content/themes/plant/images/cover_homepage.png');">
      </div>
      <div class="container pt-0 ">
        <div class="row">
          <div class="col-lg-6 col-md-7 mx-auto text-center">
            <h1 class="title text-white">{{ shopName }}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container my-5" v-if="loading">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner style="width: 3rem; height: 3rem;" label="Loading..."></b-spinner>
      </div>
      <p class=" text-center">กรุณารอสักครู่...</p>
    </div>
    <div v-else class="z-index-10000 my-6 ">
      <div class="pt-0 pb-0">
        <div v-if="promotions.length != 0" class="container">
          <h2 class="mb-3">โปรโมชั่น</h2>
          <div v-for="(item, index) in promotions" :key="index" :class="[
            item.id == active_promo ? 'bg-primary' : '',
            'row rounded shadow-sm px-2 mx-1 py-2 cursor-pointer',
          ]" @click="setPromo(item.id)">
            <div class=" d-flex flex-row ">
              <img class=" rounded mr-2" width="75px" height="75px" :src="
                item.promotion_image_url
                  ? item.promotion_image_url
                  : '/img/promotion_placeholder.jpg'
              " />
              <p :class="[
                item.id == active_promo ? 'text-white' : '',
                'line-height-1 mb-0',
              ]">
                <strong>{{ item.name }}</strong> <br />
                <span class="limit-text text-xs">{{ item.description }}</span><br />
                <span v-if="item.startDate" :class="[
                  item.id == active_promo ? '' : 'text-gray ',
                  'text-xs',
                ]">
                  {{ convertDate(item.startDate) }} -
                  {{ convertDate(item.endDate) }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="section pt-4 pb-0">
        <div class="container">
          <h2 class="mb-3">เลือกหมวดหมู่สินค้า</h2>
          <div class=" row-scroll">
            <base-button outline type="primary" class=" rounded-pill py-1 px-2  no-wrap text-capitalize" size="md"
              @click="setCategory(undefined)">All Product</base-button>
            <base-button v-for="(item, index) in categories" :key="index" outline type="primary" :class="[
              item.id == selectedCategoryId ? 'bg-primary text-white' : '',
              'rounded-pill py-1 px-2  no-wrap text-capitalize',
            ]" size="md" @click="setCategory(item.id)">{{ item.name }}</base-button>
          </div>
        </div>
      </div>

      <div class="section pt-2">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div v-if="!filteredProducts && (selectedCategoryId || active_promo)" class="row">
                <p class=" my-5 py-5 text-center w-100 ">ไม่พบสินค้า</p>
              </div>
              <div v-if="filteredProducts" class="row">
                <product-card v-for="(item, index) in filteredProducts" :key="index" :itemImg="item.product_image_url"
                  :itemId="item.id" :itemName="item.name" :itemPrice="item.price" :shopName="shopName" :shopId="shopId"
                  :quantity="item.inventory.quantity"
                  :discount="item.discount ? item.discount.discount_percent : null" />
              </div>
              <div class="row" v-else>
                <product-card v-for="(item, index) in products" :key="index" :itemId="item.id"
                  :itemImg="item.product_image_url" :itemName="item.name" :itemPrice="item.price" :shopName="shopName"
                  :shopId="shopId" :quantity="item.inventory.quantity"
                  :discount="item.discount ? item.discount.discount_percent : null" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VBTooltip } from "bootstrap-vue/esm/directives/tooltip/tooltip";
import "bootstrap-vue/dist/bootstrap-vue.min.css";
import * as api from "@/services";
import ProductCard from "./components/ProductCard.vue";

export default {
  bodyClass: "ecommerce-page",
  components: {
    ProductCard,
  },
  data() {
    return {
      categories: [],
      category: "",
      shopName: "",
      products: null,
      selectedCategoryId: null,
      promotions: [],
      productsPromo: [],
      active_promo: null,
      loading: true,
    };
  },
  directives: {
    BTooltip: VBTooltip,
  },
  computed: {
    filteredProducts() {
      let self = this;
      if (this.products && this.active_promo && this.selectedCategoryId) {
        let temp = self.products.filter(function (o1) {
          return self.productsPromo.some(function (o2) {
            return o1.id === o2.productId; // assumes unique id
          });
        });
        return temp.filter((x) => x.categoryId == self.selectedCategoryId);
      }
      if (this.products && this.active_promo) {
        return self.products.filter(function (o1) {
          // filter out
          return self.productsPromo.some(function (o2) {
            return o1.id === o2.productId; // assumes unique id
          });
        });
      }
      if (this.products && this.selectedCategoryId) {
        return this.products.filter(
          (x) => x.categoryId == this.selectedCategoryId
        );
      }
      return null;
    },
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    convertDate(date) {
      let year = date.substring(0, 4);
      let month = date.substring(5, 7);
      let day = date.substring(8, 10);
      return day + "/" + month + "/" + year;
    },

    async setItem() {
      this.shopId = this.$route.params.shopid
        ? parseInt(this.$route.params.shopid)
        : null;

      api.getShopById(this.shopId).then((res) => {
        this.shopName = res.data.name;
      });
      if (await this.shopId) {
        await api
          .getProductsByShopId(this.$route.params.shopid)
          .then(async (res) => {
            this.products = await res.data.results;
          })
          .catch((err) => console.log(err));
      } else {
        await api
          .getProducts()
          .then(async (res) => {
            this.products = await res.data.results;
          })
          .catch((err) => console.log(err));
      }
      await this.setCategories();
    },
    async setCategories() {
      for (const item of this.products) {
        if (!this.categories.some((x) => x.id === item.category.id)) {
          this.categories.push(item.category);
        }
      }
    },
    setCategory(id) {
      if (this.selectedCategoryId == id) {
        this.selectedCategoryId = null;
        this.$router.push({
          query: {
            category: undefined,
            promotion: this.active_promo ? this.active_promo : undefined,
          },
        });
      } else {
        this.selectedCategoryId = id;
        this.$router.push({
          query: {
            category: id,
            promotion: this.active_promo ? this.active_promo : undefined,
          },
        });
      }
    },
    setPromo(id) {
      if (this.active_promo == id) {
        this.active_promo = null;
        this.$router.push({
          query: {
            promotion: undefined,
            category: this.selectedCategoryId
              ? this.selectedCategoryId
              : undefined,
          },
        });
      } else {
        this.active_promo = id;
        this.$router.push({
          query: {
            promotion: id,
            category: this.selectedCategoryId
              ? this.selectedCategoryId
              : undefined,
          },
        });

        this.getProductsPromotion();
      }
    },
    getProductsPromotion() {
      if (this.active_promo) {
        api.getProductsPromotion(this.active_promo).then((res) => {
          this.productsPromo = res.data.results;
        });
      } else {
        this.productsPromo = [];
      }
    },
  },
  created() {
    api.getPromotionByShopId(this.$route.params.shopid).then((res) => {
      this.promotions = res.data.results;
    });
    this.setItem();
    if (this.$route.query.promotion) {
      this.setPromo(this.$route.query.promotion);
    }
    if (this.$route.query.category) {
      this.setCategory(this.$route.query.category);
    }
    this.loading = false;
  },
};
</script>
<style>
.ecommerce-page a.carousel-control-prev,
.ecommerce-page a.carousel-control-next {
  z-index: 9999;
}

.z-index-10000 {
  z-index: 10;
}

.skew-separator:after {
  z-index: 0 !important;
}

@media only screen and (min-width: 768px) {
  .container-5-3 {
    margin: 5rem 0 3rem 0;
  }
}
</style>
<style scoped>
.sqr-img {
  width: 200px !important;
  height: 200px !important;
  object-fit: cover;
  border-radius: 50%;
}

.row-scroll {
  display: flex;
  display: -webkit-flex;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-flex-flow: row nowrap;
}

.row-scroll::-webkit-scrollbar {
  display: none;
}

.no-wrap {
  white-space: nowrap;
}

.text-xs {
  font-size: 0.9rem;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
