import axios from "../axios";

function getPromotions() {
  return axios.get("/promotions?sort_by=startDate.desc&status=Approved");
}

function getPromotionById(id) {
  return axios.get("/promotions/" + id);
}

function getPromotionByShopId(id) {
  return axios.get("/promotions?status=Approved&shopId=" + id);
}


function getPromotionsByDate(limit, page) {
  return axios.get(
    "/promotions?status=Approved&sort_by=startDate.desc" + "&limit=" + limit + "&page=" + page
  );
}

function getProductsPromotion(id) {
  return axios.get("/product-promotion?promotionId=" + id);
}

export {
  getPromotions,
  getPromotionById,
  getPromotionsByDate,
  getProductsPromotion,
  getPromotionByShopId
};
