<template>
  <div id="app">
    <router-view name="header"></router-view>
    <main>
      <fade-transition origin="center" mode="out-in" :duration="300">
        <router-view />
      </fade-transition>
    </main>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
import { FadeTransition } from "vue2-transitions";
import * as api from "@/services";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    FadeTransition,
  },
  computed: {
    ...mapState("customer", ["userId"]),
  },
  methods: {
    ...mapActions("customer", ["lineLogin"]),
    ...mapMutations("customer", [
      "setCustomerData",
      "setUserId",
      "setDisplayName",
    ]),
    ...mapMutations("global", ["SET_SHOP_CATEGORIES", "SET_SHOPS"]),

    login() {
      api.getCustomerByLineUid(this.userId).then(res => {
        if (res.data.results.length > 0) {
          api
            .getCustomer(this.userId)
            .then((res) => {
              this.setCustomerData(res.data);
            })
        }
        else {
          if (this.$router.currentRoute.path != "/register")
            this.$router.push({ path: "/register" });
        }
      })
    },
  },
  async mounted() {
    this.$liff.init({ liffId: "1657027141-dDQA0kWV" });
    if (this.$liff.isInClient()) {
      this.$liff.ready.then(async () => {
        await this.$liff
          .getProfile()
          .then(async (profile) => {
            await this.lineLogin(profile); 
          })
          .catch(function (error) {
            console.log("Error getting profile: " + error);
            throw new Error("Error getting profile: " + error);
          });
      });
    }
    api
      .getShopCategories()
      .then((res) => {
        this.SET_SHOP_CATEGORIES(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });

    api
      .getShop()
      .then((res) => {
        this.SET_SHOPS(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>
