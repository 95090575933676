import axios from "../axios";

function getShop() {
  return axios.get("/shops");
}

function getShopById(id) {
  return axios.get("/shops/" + id);
}
function getShopSortByCategoryId() {
  return axios.get("/shops?sort_by=categoryId.asc");
}

function getShopByCategoryId(id) {
  return axios.get("/shops?categoryId=1");
}

export { getShop, getShopByCategoryId, getShopSortByCategoryId, getShopById };
